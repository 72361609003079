// Full Calendar Plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// eslint-disable-next-line object-curly-newline
import { ref, computed, watch, onMounted } from "@vue/composition-api";
import store from "@/store";
import { getDateWithoutTime } from "@/libs/helper";

export default function userCalendar() {
  // Use toast
  const toast = useToast();
  const adminFilter = ref(false);
  const clientFilter = ref(null);

  const refCalendar = ref(null);

  let calendarApi = null;

  onMounted(() => {
    calendarApi = refCalendar.value.getApi();
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        navigator.userAgent.substr(0, 4)
      )
    ) {
      calendarApi.changeView("listMonth");
    } else {
      calendarApi.changeView("dayGridMonth");
    }
  });

  const changeView = () => {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        navigator.userAgent.substr(0, 4)
      )
    ) {
      calendarApi.changeView("listMonth");
    } else {
      calendarApi.changeView("dayGridMonth");
    }
  };

  const calendarsColor = {};

  const blankEvent = {
    id: 0,
    title: "",
    content: "",
    meeting_remark: "",
    start: "",
    end: "",
    allDay: false,
    client_id: null,
    project_id: null,
    contract_id: null,
    users: null,
  };

  const event = ref(JSON.parse(JSON.stringify(blankEvent)));

  const clearEventData = () => {
    event.value = JSON.parse(JSON.stringify(blankEvent));
  };

  // *===========================================================================---*
  // *--------- Calendar API Function/Utils --------------------------------------------*
  // Template Future Update: We might move this utils function in its own file
  // *===========================================================================---*

  // ------------------------------------------------
  // (UI) addEventInCalendar
  // ? This is useless because this just add event in calendar and not in our data
  // * If we try to call it on new event then callback & try to toggle from calendar we get two events => One from UI and one from data
  // ------------------------------------------------
  // const addEventInCalendar = eventData => {
  //   toast({
  //     component: ToastificationContent,
  //     position: 'bottom-right',
  //     props: {
  //       title: 'Event Added',
  //       icon: 'CheckIcon',
  //       variant: 'success',
  //     },
  //   })
  //   calendarApi.addEvent(eventData)
  // }

  // ------------------------------------------------
  // (UI) updateEventInCalendar
  // ------------------------------------------------
  const updateEventInCalendar = (
    updatedEventData,
    propsToUpdate,
    extendedPropsToUpdate
  ) => {
    toast({
      component: ToastificationContent,
      props: {
        title: "Event Updated",
        icon: "CheckIcon",
        variant: "success",
      },
    });
    /*
    const existingEvent = calendarApi.getEventById(updatedEventData.id)

    // --- Set event properties except date related ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setProp
    // dateRelatedProps => ['start', 'end', 'allDay']
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < propsToUpdate.length; index++) {
      const propName = propsToUpdate[index]
      existingEvent.setProp(propName, updatedEventData[propName])
    }

    // --- Set date related props ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setDates
    existingEvent.setDates(updatedEventData.start, updatedEventData.end, { allDay: updatedEventData.allDay })

    // --- Set event's extendedProps ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setExtendedProp
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < extendedPropsToUpdate.length; index++) {
      const propName = extendedPropsToUpdate[index]
      existingEvent.setExtendedProp(propName, updatedEventData.extendedProps[propName])
    }
    */
  };

  // ------------------------------------------------
  // (UI) removeEventInCalendar
  // ------------------------------------------------
  const removeEventInCalendar = (eventId) => {
    toast({
      component: ToastificationContent,
      props: {
        title: "Event Removed",
        icon: "TrashIcon",
        variant: "danger",
      },
    });
    calendarApi.getEventById(eventId).remove();
  };

  // ------------------------------------------------
  // grabEventDataFromEventApi
  // ? It will return just event data from fullCalendar's EventApi which is not required for event mutations and other tasks
  // ! You need to update below function as per your extendedProps
  // ------------------------------------------------
  const grabEventDataFromEventApi = (eventApi) => {
    console.log("eventApi", eventApi);
    const {
      id,
      title,
      start,
      end,
      // eslint-disable-next-line object-curly-newline
      extendedProps: {
        client_id,
        content,
        contract_id,
        meeting_remark,
        meeting_stage_id,
        meeting_tag_id,
        project_id,
        users,
        address,
      },
      allDay,
    } = eventApi;

    return {
      id,
      title,
      start,
      end,
      extendedProps: {
        client_id,
        content,
        contract_id,
        meeting_remark,
        meeting_stage_id,
        meeting_tag_id,
        project_id,
        users,
        address,
      },
      allDay,
    };
  };

  const addEvent = (eventData) => {
    console.log("useMeeting/addEvent", eventData);

    store.dispatch("meeting/addEvent", eventData).then(() => {
      // eslint-disable-next-line no-use-before-define
      refetchEvents();
    });
  };
  const updateEvent = (eventData) => {
    eventData.users;
    var users = [];
    eventData.users.forEach((item) => {
      if (typeof item == "object") {
        users.push(item.id);
      } else {
        users.push(item);
      }
    });
    eventData.users = users;
    store
      .dispatch("meeting/addEvent", eventData)
      .then((response) => {
        const updatedEvent = eventData;

        const propsToUpdate = ["id", "title", "url"];
        const extendedPropsToUpdate = [
          "calendar",
          "guests",
          "location",
          "description",
        ];

        updateEventInCalendar(
          updatedEvent,
          propsToUpdate,
          extendedPropsToUpdate
        );

        //console.log('refetchEvents')
        refetchEvents();
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.response.message,
            icon: "XCircleIcon",
            variant: "danger",
          },
        });
      });
  };

  const removeEvent = () => {
    console.log("useMeeting/removeEvent");

    const eventId = event.value.id;
    console.log("eventId", eventId);
    store.dispatch("meeting/removeEvent", { id: eventId }).then(() => {
      removeEventInCalendar(eventId);
    });
  };

  const refetchEvents = () => {
    calendarApi.refetchEvents();
  };

  const selectedCalendars = computed(
    () => store.state.meeting.selectedCalendars
  );

  watch([selectedCalendars, clientFilter], () => {
    refetchEvents();
  });

  const fetchEvents = (info, successCallback) => {
    if (!info) return;
    if (!clientFilter.value) return;
    //store.dispatch('meeting/fetchEvents', {calendars: selectedCalendars.value, is_admin:false}).then(response => {
    store
      .dispatch("app-client/fetchClientMeetings", {
        client_id: clientFilter.value,
        date_from: getDateWithoutTime(info.startStr),
        date_to: getDateWithoutTime(info.endStr),
      })
      .then((response) => {
        successCallback(response.data.meetings);
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching calendar events",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };
  // ------------------------------------------------------------------------
  // calendarOptions
  // * This isn't considered in UI because this is the core of calendar app
  // ------------------------------------------------------------------------
  const calendarOptions = ref({
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
    initialView: "dayGridMonth",
    headerToolbar: {
      start: "sidebarToggle, prev,next, title",
      end: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
    },
    events: fetchEvents,

    /*
      Enable dragging and resizing event
      ? Docs: https://fullcalendar.io/docs/editable
    */
    editable: false,

    /*
      Enable resizing event from start
      ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
    */
    eventResizableFromStart: true,

    /*
      Automatically scroll the scroll-containers during event drag-and-drop and date selecting
      ? Docs: https://fullcalendar.io/docs/dragScroll
    */
    dragScroll: true,

    /*
      Max number of events within a given day
      ? Docs: https://fullcalendar.io/docs/dayMaxEvents
    */
    dayMaxEvents: 10,

    /*
      Determines if day names and week names are clickable
      ? Docs: https://fullcalendar.io/docs/navLinks
    */
    navLinks: true,

    eventClassNames({ event: calendarEvent }) {
      // eslint-disable-next-line no-underscore-dangle
      console.log("calendarEvent", calendarEvent);
      const colorName =
        calendarsColor[calendarEvent._def.extendedProps.calendar];

      return [
        // Background Color
        `bg-light-${colorName}`,
      ];
    },
    eventClick({ event: clickedEvent }) {
      // * Only grab required field otherwise it goes in infinity loop
      // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"

      console.log("clickedEvent", clickedEvent);
      event.value = grabEventDataFromEventApi(clickedEvent);
      event.value.client_id = event.value.extendedProps.client_id;
      event.value.content = event.value.extendedProps.content;
      event.value.contract_id = event.value.extendedProps.contract_id;
      event.value.meeting_remark = event.value.extendedProps.meeting_remark;
      event.value.meeting_stage_id = event.value.extendedProps.meeting_stage_id;
      event.value.meeting_tag_id = event.value.extendedProps.meeting_tag_id;
      event.value.project_id = event.value.extendedProps.project_id;
      event.value.users = event.value.extendedProps.users;
      event.value.address = event.value.extendedProps.address;
      // eslint-disable-next-line no-use-before-define
      console.log("event.value", event.value);
      isEventHandlerSidebarActive.value = true;
    },
    customButtons: {
      sidebarToggle: {
        // --- This dummy text actual icon rendering is handled using SCSS ----- //
        text: "sidebar",
        click() {
          // eslint-disable-next-line no-use-before-define
          isCalendarOverlaySidebarActive.value =
            !isCalendarOverlaySidebarActive.value;
        },
      },
    },
    eventContent: function (arg) {
      console.log("arg", arg.event);

      var event = arg.event;
      var customHtml = "";

      // Check if the event is a system message
      if (event._def.extendedProps.is_system_message) {
        event._def.extendedProps.stage.background_color = "purple";
        event._def.extendedProps.stage.stage = "系統";
      }

      customHtml +=
        "<div style='position:relative; padding:5px;border-radius:5px; width:100%; overflow: hidden; background:" +
        event._def.extendedProps.stage.background_color +
        ";color:" +
        event._def.extendedProps.stage.font_color +
        "'>";

      if (arg.timeText) {
        customHtml +=
          '<span style="margin-top:5px;">' + arg.timeText + "</span>";
      }
      customHtml +=
        "<span style='position:absolute;right:5px;;'>[" +
        event._def.extendedProps.stage.stage +
        "]</span><br>";
      if (event._def.extendedProps.client) {
        customHtml +=
          "<span>[" +
          event._def.extendedProps.client.client_code +
          "]</span><br>";
      }
      customHtml +=
        "<span class='r10 font-xxs font-bold' style='width:100%; overflow: hidden; background:" +
        event._def.extendedProps.stage.background_color +
        ";color:" +
        event._def.extendedProps.stage.font_color +
        "'>" +
        event._def.title +
        "</span>";
      if (event._def.extendedProps.users) {
        customHtml += "<br>";
        event._def.extendedProps.users.forEach((user) => {
          customHtml +=
            '<span style="margin-right:5px;">' + user.name + "</span>";
        });
      }

      customHtml += "</div>";
      //customHtml += "<span class='r10 highlighted-badge font-xxs font-bold'>" + event._def.title + "</span>";

      return { html: customHtml };
    },

    dateClick(info) {
      /*
        ! Vue3 Change
        Using Vue.set isn't working for now so we will try to check reactivity in Vue 3 as it can handle this automatically
        ```
        event.value.start = info.date
        ```
      */
      event.value = JSON.parse(
        JSON.stringify(
          Object.assign(event.value, { start: info.date, end: info.date })
        )
      );
      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true;
    },

    /*
      Handle event drop (Also include dragged event)
      ? Docs: https://fullcalendar.io/docs/eventDrop
      ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
    */
    eventDrop({ event: droppedEvent }) {
      updateEvent(grabEventDataFromEventApi(droppedEvent));
    },
    /*
      Handle event resize
      ? Docs: https://fullcalendar.io/docs/eventResize
    */
    eventResize({ event: resizedEvent }) {
      updateEvent(grabEventDataFromEventApi(resizedEvent));
    },

    // Get direction from app state (store)
    direction: computed(() => (store.state.appConfig.isRTL ? "rtl" : "ltr")),
    rerenderDelay: 350,
  });

  const isEventHandlerSidebarActive = ref(false);

  const isCalendarOverlaySidebarActive = ref(false);

  const tags = ref([]);
  const selectAll = ref(true);

  return {
    refCalendar,
    isCalendarOverlaySidebarActive,
    isEventHandlerSidebarActive,
    calendarOptions,
    event,
    clearEventData,
    addEvent,
    updateEvent,
    removeEvent,
    refetchEvents,
    fetchEvents,
    // FIlter,
    clientFilter,
    tags,
    selectAll,
    changeView,
  };
}
