<template>
  <b-modal id="modal-lg" ok-title="submit" cancel-variant="outline-secondary" scrollable size="lg" title="創建項目"
    hide-footer no-close-on-backdrop>
    <b-alert variant="danger" :show="servicecodeOptions.length == 0">
      <div class="alert-body">請先到系統設置>項目>
        <b-link class="alert-link" :to="{ name: 'servicecodes-list' }">項目類型</b-link>頁新增項目類型
      </div>
    </b-alert>
    <div class="d-flex">
      <h5 class="mb-0">項目資料</h5>
    </div>

    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
        <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
          <b-col cols="12">
            <hr />
          </b-col>
          <b-col cols="12" md="12">
        
              <b-form-group class="required" label="項目編號" label-for="project_id" label-cols-md="4">
                <b-form-input  class=""
                  placeholder="項目編號 - 預設自動生成" disabled />
              </b-form-group>
          </b-col>
          <b-col cols="12" md="12">
            <validation-provider #default="validationContext" name="項目類型" rules="required">
              <b-form-group class="required" label="項目類型" label-for="service_code_id" label-cols-md="4">
                <v-select v-model="projectData.service_code_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="servicecodeOptions" label="name" :reduce="(option) => option.id" :clearable="false">
                </v-select>
              </b-form-group>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="12">
            <b-form-group label="合約" label-for="contract_id" label-cols-md="4">
              <v-select searchable v-model="projectData.contract_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="contractOptions" label="contract_code" :reduce="(option) => option.id" :clearable="true" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12">
            <validation-provider #default="validationContext" name="項目縮寫名稱" rules="required">
              <b-form-group class="required" label="項目縮寫名稱" label-for="project_short_name" label-cols-md="4">
                <b-form-input v-model="projectData.project_short_name" :state="getValidationState(validationContext)"
                  class="" placeholder="項目縮寫名稱" />
              </b-form-group>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="12">
            <validation-provider #default="validationContext" name="項目名稱" rules="required">
              <b-form-group class="required" label="項目名稱" label-for="project_name" label-cols-md="4">
                <b-form-input v-model="projectData.project_name" :state="getValidationState(validationContext)" class=""
                  placeholder="項目名稱" />
              </b-form-group>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="12">
            <b-form-group class="required" label="項目服務" label-for="application_code_ids" label-cols-md="4">
              <v-select multiple v-model="projectData.application_code_ids"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="applicationOptions" label="code_name"
                :reduce="(option) => option.id" :clearable="false">
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12">
            <b-form-group class="" label="狀態" label-for="project_stage_id" label-cols-md="4">
              <v-select v-model="projectData.project_stage_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="projectstageOptions" label="stage" :reduce="(option) => option.id" :clearable="true">
                <template #option="{ stage, background_color }">
                  <div class="rounded-circle d-inline-block mr-50 bg-primary" :style="{
                    background: background_color + ' !important',
                    height: '10px',
                    width: '10px',
                  }" />
                  <span> {{ stage }}</span>
                </template>

                <template #selected-option="{ stage, background_color }">
                  <div class="rounded-circle d-inline-block mr-50 bg-primary" :style="{
                    background: background_color + ' !important',
                    height: '10px',
                    width: '10px',
                  }" />
                  <span> {{ stage }}</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" class="mb-1">
            <b-form-group label="備註" label-for="remarks" label-cols-md="4">
              <quill-editor v-model="projectData.remarks" :options="options" />
            </b-form-group>
            <div class="d-flex justify-content-between align-items-center">
              <h5>額外資料</h5>
              <div class="d-flex justify-content-center align-items-center">
                <b-form-input v-model="field" type="text" class="mr-1" placeholder="輸入想新增的資料欄名" />
                <b-button v-if="field" style="width: 120px; padding:10px;" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm" variant="primary" @click="addInformation">
                  新增資料
                </b-button>
              </div>
            </div>
          </b-col>
          <b-col cols="12" md="12" v-for="(information, index) in projectData.information"
            :key="`information_${index}`">
            <b-form-group class="" :label="information.field" :label-for="`information_${index}`" label-cols-md="4">
              <b-input-group>
                <b-form-input :id="`information_${index}`" type="text" :placeholder="`輸入${information.field}...`"
                  v-model="information.value" />
                <b-input-group-append is-text>
                  <feather-icon icon="TrashIcon" class="cursor-pointer text-danger" @click="removeInformation(index)" />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <hr />
          <b-col cols="12" md="12" class="mb-1">
            <div class="d-flex justify-content-between align-items-center">
              <h5>需求文件提示設置</h5>
              <div class="d-flex justify-content-center align-items-center">
                <v-select class="document-selector" v-model="document_type_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="filteredDocuments" label="name"
                  :reduce="(option) => option.id" :clearable="true">
                </v-select>
                <b-button v-if="document_type_id" style="width: 120px; padding:10px;"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary" @click="addDocument">
                  新增文件
                </b-button>
              </div>
            </div>
          </b-col>
          <b-col cols="12" md="12" v-for="(document, index) in projectData.documents" :key="`document_${index}`">
            <b-form-group class="" :label="document.field" :label-for="`document_${index}`" label-cols-md="4">
              <b-input-group>
                <b-form-checkbox v-model="document.is_received" class="mr-1 mt-50" name="is_received"
                  inline>已接收</b-form-checkbox>
                <b-form-input :id="`document_${index}`" type="text"
                  :value="documentTypeOptions && documentTypeOptions.find(option => option.id == document.document_type_id).name"
                  disabled />
                <b-input-group-append is-text>
                  <feather-icon icon="TrashIcon" class="cursor-pointer text-danger" @click="removeDocument(index)" />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>


          <div class="d-flex mt-2 justify-content-end">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
              提交
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
              @click="hide">
              取消
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCardText,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BFormInvalidFeedback,
  BCardBody,
  BAlert,
  BLink,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ref, onMounted, watch } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";
import { max } from "@/libs/validations.js";
import { heightTransition } from "@core/mixins/ui/transition";
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  data() {
    return {
      field: '',
      document_type_id: null
    }
  },
  components: {
    BButton,
    BCardText,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCardBody,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    BOverlay,
    BFormInvalidFeedback,
    vSelect,
    BAlert,
    BLink,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    documentTypeOptions: {
      type: Array,
      required: true,
    },
    applicationOptions: {
      type: Array,
      required: true,
    },
    servicecodeOptions: {
      type: Array,
      required: true
    },
    projectstageOptions: {
      type: Array,
      required: true
    },
    clientData: {
      type: Object,
      required: true,
    },
    contractOptions: {
      type: Array,
      required: true
    }
  },
  computed: {
    filteredDocuments() {

      if (!this.projectData.documents) {
        console.log({ documentTypeOptions: this.documentTypeOptions })
        return this.documentTypeOptions
      }
      return this.documentTypeOptions.filter(option => !this.projectData.documents.some(document => document.document_type_id == option.id))
    }
  },
  methods: {
    onSubmit() {
      this.$swal({
        title: '確定提交?',
        showCancelButton: true,
        confirmButtonText: '提交',
        cancelButtonText: '取消',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.loading = true
          this.projectData.client_id = this.clientData.id
          this.$store.dispatch("app-client/addProject", this.projectData)
            .then((response) => {
              this.loading = false
              this.$swal({
                text: response.data.message,
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                showClass: {
                  popup: 'animate__animated animate__bounceIn',
                },
                buttonsStyling: false,
              })
              this.$emit('refetch-data')
              if (this.projectData.id == 0) {
                // Return to client list
                this.hide()
              }
            }).catch((error) => {
              this.loading = false
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                showClass: {
                  popup: 'animate__animated animate__bounceIn',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    },
    hide() {
      this.$bvModal.hide("modal-lg");
      this.resetProjectData();
    },
    addDocument() {
      if (!this.projectData.documents) {
        this.$set(this.projectData, 'documents', [])
      }

      let name = this.documentTypeOptions && this.documentTypeOptions.find(option => option.id == this.document_type_id).name

      const newDoc = { document_type_id: this.document_type_id, is_received: false, remark: '', name }

      this.$set(this.projectData.documents, this.projectData.documents.length, newDoc)

      this.document_type_id = null
    },
    addInformation() {
      if (!this.projectData.information) {
        this.projectData.information = []
      }
      this.projectData.information.push(JSON.parse(JSON.stringify({ field: this.field, value: '' })))
      this.field = '';
    },
    removeDocument(index) {
      this.projectData.documents.splice(index, 1)
    },
    removeInformation(index) {
      this.projectData.information.splice(index, 1)

    },
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = "hidden";
      this.projectData.invoice_items.push(
        JSON.parse(JSON.stringify(this.itemFormBlankItem))
      );
    },
    removeItem(index) {
      this.projectData.invoice_items.splice(index, 1);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
    makeToast(variant = null, title = null, content = null) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      })
    },
    changeProject() {
      console.log(this.projectData.service_code_id)
      // Get the servoceOption from selected id
      var description = serviceOption[0].description2
      this.projectData.invoice_items[0].description = description
    }
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const itemFormBlankItem = {
      amount: 0,
      description: "",
      quantity: 1,
    };

    const projectBlankData = ref({
      id: 0,
      service_code_id: null,
      project_short_name: "",
      project_name: "",
      application_code_ids: [],
      project_stage_id: null,
      remarks: "",
      information: [],
    })

    const projectData = ref(projectBlankData.value);

    const resetProjectData = () => {
      projectData.value = JSON.parse(JSON.stringify(projectBlankData.value))
    };

    const options = ref({
      placeholder: "",
    });

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetProjectData);

    //Invoice

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      projectData,
      itemFormBlankItem,
      options,
      resetProjectData
    };
  },
};
</script>

<style lang="scss" scoped>
.vs__selected path {
  fill: white;
}

.v-select {

  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.ql-editor {
  height: 300px;
}

.modal-body {
  min-height: 80vh !important;
}

.remark::after {
  content: "******";
}

.document-selector {
  min-width: 200px;
  max-width: 100%;
}
</style>