<template>
    <div class="quill-editor">
        <!-- 图片上传组件辅助-->
        <quill-editor ref="QuillEditor" :content="content" :options="editorOption"
            @input="val => $emit('update:content', val)">
        </quill-editor>
    </div>
</template>

<script>
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {

    components: {
        quillEditor,
    },
    props: {
        content: {
            type: String,
            default: ''
        },
        focus: {
            type: Boolean,
            default: true
        },
        default_focus: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        focus(val) {
            console.log('focus', val)
            this.$refs.QuillEditor.quill.enable(val)
        }
    },
    mounted() {
        this.$refs.QuillEditor.quill.enable(this.default_focus)
    },
    setup(props) {
        const editorOption = {
            modules: {
                toolbar: {
                    container: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote', 'code-block'],
                        [{ 'header': 1 }, { 'header': 2 }],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'script': 'sub' }, { 'script': 'super' }],
                        [{ 'indent': '-1' }, { 'indent': '+1' }],
                        [{ 'direction': 'rtl' }],
                        [{ 'size': ['small', false, 'large', 'huge'] }],
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        [{ 'color': [] }, { 'background': [] }],
                        [{ 'font': ['SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial'] }],
                        [{ 'align': [] }],
                        ['clean'],
                        ['link']
                        //,['image']
                    ],
                    handlers: {
                        image: (value) => {
                            console.log("image", value);
                            if (value) {
                                document.querySelector('.avatar-uploader input').click()
                            } else {
                                this.quill.format("image", false);
                            }
                        },
                    },
                },
            },
        };

        return {
            editorOption,
        }
    }
}
</script>

<style>
.ql-editor {
    min-height: 350px;
}
</style>
