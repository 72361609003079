<template>
  <b-modal id="modal-contract" ok-title="submit" cancel-variant="outline-secondary" scrollable size="lg" title="創建合約"
    hide-footer no-close-on-backdrop>
    <div class="d-flex">
      <h5 class="mb-0">合約資料</h5>
    </div>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
        <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
          <b-col cols="12">
            <hr />
          </b-col>
          <!-- <b-col cols="12" md="12">
            <b-form-group class="required" label="Client ID" label-for="client_id" label-cols-md="4">
              <b-form-input id="client_id" v-model="contractData.client_id" disabled />
            </b-form-group>
          </b-col> -->
          <b-col cols="12" md="12">
            <validation-provider #default="validationContext" name="客戶" rules="required">
              <b-form-group class="required" label="客戶" label-for="client_id" label-cols-md="4">
                <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :value="contractData.client_id"
                  :filter="clientSearch" :options="clientOptions" label="client_code" class="w-100"
                  :reduce="(val) => val.id" @input="(val) => changeClient(val)">
                  <template #option="{ client_code, company_name_cn }">
                    [{{ client_code }}] {{ company_name_cn }}
                  </template>
                  <template #selected-option="{ client_code, company_name_cn }">
                    [{{ client_code }}] {{ company_name_cn }}
                  </template>
                </v-select>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>


          <!-- Field: 合約日期 -->
          <b-col cols="12" md="12">
            <validation-provider #default="validationContext" name="合約日期" rules="required">
              <b-form-group class="contract_date required" label="合約日期" label-for="contract_date" label-cols-md="4">
                <b-form-datepicker id="contract_date" :state="getValidationState(validationContext)"
                  placeholder="Choose a date" local="en" v-model="contractData.contract_date" :date-format-options="{
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }" />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Field: 合約開始日期 -->
          <b-col cols="12" md="12">
            <b-form-group class="start_date" label="合約開始日期" label-for="start_date" label-cols-md="4">
              <b-form-datepicker id="start_date" placeholder="Choose a date" local="en"
                v-model="contractData.start_date" :date-format-options="{
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }" />
            </b-form-group>
          </b-col>
          <!-- Field: 合約完結日期 -->
          <b-col cols="12" md="12">
            <b-form-group class="end_date" label="合約完結日期" label-for="end_date" label-cols-md="4">
              <b-form-datepicker id="end_date" placeholder="Choose a date" local="en" v-model="contractData.end_date"
                :date-format-options="{
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }" />
            </b-form-group>
          </b-col>
          <!-- 合約模版-->
          <b-col cols="12" md="12">
            <validation-provider #default="validationContext" name="合約模版" rules="required">
              <b-form-group class="required" label="合約模版" label-for="contract_template_id" label-cols-md="4">
                <b-form-input style="display: none" id="contract_template_id"
                  :state="getValidationState(validationContext)" v-model="contractData.contract_template_id" />
                <v-select v-model="contractData.contract_template_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="templateOptions" label="name"
                  :reduce="(option) => option.id" :clearable="false">
                </v-select>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="12">
            <validation-provider #default="validationContext" name="合約名稱" rules="required">
            <b-form-group class="required" label="合約名稱" label-for="contract_name" label-cols-md="4">
              <b-form-input id="contract_name" v-model="contractData.contract_name" :state="getValidationState(validationContext)" />
            </b-form-group>
          </validation-provider>
          </b-col>
          <b-col cols="12" md="12">
            <b-form-group class="" label="公司名稱(中文)" label-for="company_name_cn" label-cols-md="4">
              <b-form-input id="company_name_cn" v-model="contractData.company_name_cn" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12">
            <b-form-group class="" label="公司名稱(英文)" label-for="company_name_en" label-cols-md="4">
              <b-form-input id="company_name_en" v-model="contractData.company_name_en" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12">
            <b-form-group class="" label="登記/通訊地址" label-for="br_address" label-cols-md="4">
              <b-form-input id="br_address" v-model="contractData.br_address" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12">
            <b-form-group class="" label="商業登記號碼" label-for="br_no" label-cols-md="4">
              <b-form-input id="br_no" v-model="contractData.br_no" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12">
            <b-form-group class="" label="聯絡人名稱" label-for="contact_name" label-cols-md="4">
              <b-form-input id="contact_name" v-model="contractData.contact_name" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12">
            <b-form-group class="" label="聯絡人職位" label-for="contact_title" label-cols-md="4">
              <b-form-input id="contact_title" v-model="contractData.contact_title" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12">
            <b-form-group class="" label="聯絡人電話" label-for="contact_phone" label-cols-md="4">
              <b-form-input id="contact_phone" v-model="contractData.contact_phone" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12">
            <b-form-group class="" label="身份證號碼" label-for="id_number" label-cols-md="4">
              <b-form-input id="id_number" v-model="contractData.id_number" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12">
            <b-form-group class="" label="備註" label-for="remark" label-cols-md="4">
              <b-form-textarea id="remark" v-model="contractData.remark" trim />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12">
              <b-form-group class="" label="銷售顧問" label-for="sale_id" label-cols-md="4">
                <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="contractData.sale_id"
                :options="userOptions"
                label="name"
                class="w-100"
                :reduce="(val) => val.id"
              />
              </b-form-group>
          </b-col>
          <hr />
          <div class="d-flex mt-2 justify-content-end">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
              提交
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
              @click="hide">
              取消
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </validation-observer>
  </b-modal>
</template>
<script>
import {
  BButton,
  BModal,
  VBModal,
  BCardText,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BFormTextarea,
  BRow,
  BCol,
  BFormInvalidFeedback,
  BCardBody,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ref, onMounted, watch } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { heightTransition } from "@core/mixins/ui/transition";
import clientStoreModule from "@/views/client/client/clientStoreModule";
import store from "@/store";

export default {
  components: {
    BButton,
    BModal,
    VBModal,
    BCardText,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BFormInvalidFeedback,
    BFormDatepicker,
    BCardBody,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    contractData: {
      type: Object,
      required: true,
    },
    clientOptions: {
      type: Array,
      required: true,
    },
    templateOptions: {
      type: Array,
      required: true,
    },
    userOptions:{
      type: Array,
      required:true
    }
  },
  methods: {
    changeClient(client_id) {
      console.log('changeClient', client_id);
      this.$store.dispatch('app-client/fetchClient', {id: client_id})
      .then(response => {
          let client = response.data.client;
          this.contractData.client_id = client.id;
          this.contractData.company_name_cn = client.company_name_cn;
          this.contractData.company_name_en = client.company_name_en;
          this.contractData.br_address = client.br_address;
          this.contractData.br_no = client.br_no;
          this.contractData.contacts = client.contacts;
          this.contractData.sale_id = client.sale_id;
          if(client.contacts && client.contacts.length > 0) {
            this.contractData.contact_name = client.contacts[0].contact_name;
            this.contractData.contact_title = client.contacts[0].contact_title;
            this.contractData.contact_phone = client.contacts[0].contact_phone;
            this.contractData.id_number = client.contacts[0].id_number;
          }
      })
    },
    clientSearch(options, search) {
      var returnOptions = options.filter(item => {
        return item.client_code.toLowerCase().includes(search.toLowerCase()) || item.company_name_cn.toLowerCase().includes(search.toLowerCase()) || item.company_name_en.toLowerCase().includes(search.toLowerCase())
      })
      return returnOptions
    },
    hide() {
      this.$bvModal.hide("modal-contract")
    },
    onSubmit() {
      this.$swal({
        title: "確定創建合約?",
        showCancelButton: true,
        confirmButtonText: "提交",
        cancelButtonText: '取消',
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.loading = true
          console.log(this.contractData)
          this.$store.dispatch("app-client/addContract", this.contractData)
            .then((response) => {
              this.loading = false
              this.$swal({
                text: response.data.message,
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                showClass: {
                  popup: 'animate__animated animate__bounceIn',
                },
                buttonsStyling: false,
              })
              this.$emit('refetch-data')
              if (this.contractData.id == 0) {
                // Return to client list
                this.hide()
              }
            }).catch((error) => {
              this.loading = false
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                showClass: {
                  popup: 'animate__animated animate__bounceIn',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const contractBlankData = ref({
      id: 0,
    });
    const resetContractData = () => {
      props.contractData = JSON.parse(JSON.stringify(contractBlankData.value));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetContractData);
    // contract_id client_id contract_date contract_template_id

    const APP_CLIENT_STORE_MODULE_NAME = "app-client";
    if (!store.hasModule(APP_CLIENT_STORE_MODULE_NAME))
      store.registerModule(APP_CLIENT_STORE_MODULE_NAME, clientStoreModule);




    return {
      loading,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>