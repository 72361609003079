<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <h4 class="mb-0">基本資料</h4>
    </div>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
        <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
          <b-row>
            <!--Field: 公司名稱(中文) -->
            <b-col cols="12">
              <hr />
            </b-col>
            <b-col cols="12" md="6">
              <validation-provider #default="validationContext" name="公司名稱(中文)" rules="required">
                <b-form-group class="required" label="公司名稱(中文)" label-for="company_name_cn" label-cols-md="4">
                  <b-form-input id="company_name_cn" :state="getValidationState(validationContext)"
                    v-model="clientData.company_name_cn" />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Field: 公司名稱(英文) -->
            <b-col cols="12" md="6">
              <validation-provider #default="validationContext" name="公司名稱(英文)" rules="required">
                <b-form-group class="required" label="公司名稱(英文)" label-for="company_name_en" label-cols-md="4">
                  <b-form-input id="company_name_en" :state="getValidationState(validationContext)"
                    v-model="clientData.company_name_en" placeholder="公司名稱(英文)" />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Field: 集團-->
            <b-col cols="12" md="6">
              <b-form-group label="集團" label-for="group-code" label-cols-md="4">
                <v-select v-model="clientData.group_code_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="groupcodeData" label="group_code" :reduce="(option) => option.id" :clearable="true">
                  <template slot="option" slot-scope="option">
                    [{{ option.group_code }}] {{ option.name_of_group }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group label="Sales" label-for="group-code" label-cols-md="4">
                <v-select v-model="clientData.sale_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="userOptions" label="name" :reduce="(option) => option.id" :clearable="false">
                </v-select>
              </b-form-group>
            </b-col>
            <!-- -->
            <b-col cols="12">
              <h4 class="mt-2">公司資料</h4>
            </b-col>
            <b-col cols="12" class="ml-0">
              <hr />
            </b-col>
            <!-- Field: 商業登記號碼 -->
            <b-col cols="12" md="6">
              <b-form-group class="" label="商業登記號碼" label-for="br_no" label-cols-md="4">
                <b-form-input id="br_no" v-model="clientData.br_no" placeholder="商業登記號碼" />
              </b-form-group>
            </b-col>
            <!-- Field: 登記/通訊地址 -->
            <b-col cols="12" md="6">
              <b-form-group class="" label="登記/通訊地址" label-for="br_address" label-cols-md="4">
                <b-form-input id="br_address" v-model="clientData.br_address" placeholder="登記/通訊地址" />
              </b-form-group>
            </b-col>
            <!-- Field:在港僱用人數 -->
            <b-col cols="12" md="6">
              <b-form-group class="" label="在港僱用人數" label-for="no_of_employees" label-cols-md="4">
                <b-form-input id="no_of_employees" placeholder="在港僱用人數" v-model="clientData.no_of_employees" />
              </b-form-group>
            </b-col>
            <!-- Field: 行業 -->
            <b-col cols="12" md="6">
              <b-form-group class="" label="行業" label-for="customer_type" label-cols-md="4">
                <b-form-input id="customer_type" placeholder="行業" v-model="clientData.customer_type" />
              </b-form-group>
            </b-col>
            <!-- Field: 業務性質 -->
            <b-col cols="12" md="6">
              <b-form-group class="" label="業務性質" label-for="business_nature" label-cols-md="4">
                <b-form-input id="business_nature" placeholder="業務性質" v-model="clientData.business_nature" />
              </b-form-group>
            </b-col>
            <!-- Field: 備註-->

          </b-row>
          <b-row>
            <b-col cols="12" class="d-flex justify-content-between align-item-center">
              <h4 class="mt-2">聯絡人資料</h4>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="addContact" class="p-1">
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span>新增聯絡人</span>
              </b-button>
            </b-col>
            <b-col cols="12" class="ml-0">
              <hr />
            </b-col>
          </b-row>

          <b-row v-for="(contact, index) in clientData && clientData.contacts" :key="`contact_${index}`" class="mb-1">
            <b-col cols="12">
              <div class="d-flex border rounded">
                <b-row class="flex-grow-1 p-2">
                  <!--Form-->
                  <b-col cols="12" md="6">
                    <b-form-group class="" :label="`聯絡人名稱[${index + 1}]`" :label-for="`contact_name[${index + 1}]`"
                      label-cols-md="4">
                      <b-form-input :id="`contact_name[${index + 1}]`" v-model="contact.contact_name" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group class="" :label="`聯絡人職位[${index + 1}]`" :label-for="`contact_title[${index + 1}]`"
                      label-cols-md="4">
                      <b-form-input :id="`contact_title[${index + 1}]`" v-model="contact.contact_title" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6">
                    <b-form-group class="" :label="`聯絡電話[${index + 1}]`" :label-for="`contact_phone[${index + 1}]`"
                      label-cols-md="4">
                      <b-form-input :id="`contact_phone[${index + 1}]`" v-model="contact.contact_phone" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6">
                    <b-form-group class="" :label="`身份證號碼[${index + 1}]`" :label-for="`id_number[${index + 1}]`"
                      label-cols-md="4">
                      <b-form-input :id="`id_number[${index + 1}]`" v-model="contact.id_number" />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6">
                    <b-form-group class="" :label="`聯絡電郵[${index + 1}]`" :label-for="`contact_email_${index + 1}`"
                      label-cols-md="4">
                      <b-form-input :id="`contact_email_${index + 1}`" v-model="contact.contact_email" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <div class="
                              d-flex
                              flex-column
                              justify-content-between
                              border-left
                              py-50
                              px-25
                            ">
                  <feather-icon size="16" icon="XIcon" class="cursor-pointer text-danger"
                    @click="removeContact(index)" />
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" class="d-flex justify-content-between align-item-center">
              <h4 class="mt-2">額外資料</h4>
              <div class="d-flex justify-content-center align-items-center">
                <b-form-input v-model="field" type="text" class="mr-1" placeholder="輸入想新增的資料欄名" />
                <b-button v-if="field" style="width: 120px; padding:10px;" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary" @click="addInformation">
                  新增資料
                </b-button>
              </div>
            </b-col>
            <b-col cols="12" class="ml-0">
              <hr />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6" v-for="(information, index) in clientData.information" :key="`information_${index}`">
              <b-form-group class="" :label="information.field" :label-for="`information_${index}`" label-cols-md="4">
                <b-input-group>
                  <b-form-input
                    :id="`information_${index}`"
                    type="text"
                    :placeholder="`輸入${information.field}...`"
                    v-model="information.value"
                  />
                  <b-input-group-append is-text @click="removeInformation(index)">
                    <feather-icon
                      icon="TrashIcon"
                      class="cursor-pointer text-danger"
                      
                    />
                  </b-input-group-append>
                </b-input-group>
            </b-form-group>
            </b-col>


          </b-row>


          <b-row v-if="clientData.id > 0">
            <b-col cols="12" md="12">
              <b-form-group class="" label="備註" label-for="remark">
                <text-editor :content.sync="clientData.remark" :focus="focus" :default_focus="false" id="remark" />
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
              {{ button_text }}
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
              @click="hide">
              取消
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
  BFormDatepicker,
  BInputGroupAppend,
  BInputGroup,
  BFormInvalidFeedback,
  BOverlay,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref, onMounted, watch } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import "animate.css";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";
import { max } from "@/libs/validations.js";
import { heightTransition } from "@core/mixins/ui/transition";
import TextEditor from '@/layouts/components/TextEditor.vue';

export default {
  data() {
    return {
      focus: false,
      field: '',
    }
  },
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormCheckbox,
    BFormDatepicker,
    BInputGroupAppend,
    BInputGroup,
    BFormInvalidFeedback,
    BOverlay,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    TextEditor
  },
  methods: {
    addInformation()
    {
      if(!this.clientData.information)
      {
        this.clientData.information = []
      }
      this.clientData.information.push(JSON.parse(JSON.stringify({field: this.field, value: ''})))
      this.field = '';
    },
    removeInformation(index)
    {
      this.clientData.information.splice(index, 1)
      
    },
    addContact() {
      if (!this.clientData.contacts) {
        this.clientData.contacts = [];
      }

      this.clientData.contacts.push({
        contact_name: '',
        contact_title: '',
        contact_phone: '',
        contact_email: '',
        id_number: '',
      });

      console.log(this.clientData)
    },
    removeContact(index) {
      this.clientData.contacts.splice(index, 1);
    },
    removeItem(index) {
      this.clientData.directors.splice(index, 1);
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    hide() {
      this.$router.replace("/clients/list");
    },
    onSubmit() {
      this.$swal({
        title: "確認儲存?",
        showCancelButton: true,
        confirmButtonText: "儲存",
        cancelButtonText: '取消',
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("app-client/addClient", this.clientData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              if (this.clientData.id == 0) {
                // Return to client list
                this.hide();
              }
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  watch: {
    'clientData.id': function (newValue) {
      console.log('clientData.id', newValue)
      this.focus = false;
      this.$nextTick(() => {
        this.focus = true;
      });
    }
  },
  props: {
    clientData: {
      type: Object,
      required: true,
    },
    groupcodeData: {
      type: Array,
      required: true,
    },
    userOptions: {
      type: Array,
      required: true,
    },

  },
  setup(props, { emit }) {
    const loading = ref(false);
    const button_text = ref('')
    const resetclientData = () => {
      props.clientData = JSON.parse(JSON.stringify({}));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetclientData);

    const checkEdit = () => {
      if (props.clientData.id > 0) {
        button_text.value = "修改";
      } else {
        button_text.value = "新增";
      }
    };

    checkEdit();

    watch(
      () => props.clientData,
      (selection, prevSelection) => {
        checkEdit();
      }
    );

    return {
      button_text,
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.required>label::after {
  content: " **";
  color: red;
}

.vs__selected path {
  fill: white;
}

.v-select {

  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.add-new-group-header {
  color: #28c76f;
  margin-left: 15px;
}
</style>
