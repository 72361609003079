<template>
    <b-card no-body class="client-header">
        <b-card-body class="text-center">
            <!--           <h2 class="text-primary">
             {{ clientData.client_code }} {{ clientData.company_name_cn }} 
          </h2> -->
            <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :value="clientData.id" :filter="clientSearch"
                :options="clientOptions" label="client_code" class="w-100" :reduce="(val) => val.id"
                @input="(val) => changeClient(val)">
                <template #option="{ client_code, company_name_cn, company_name_en }">
                    [{{ client_code }}] {{ company_name_cn }} {{ company_name_en }}
                </template>
                <template #selected-option="{ client_code, company_name_cn, company_name_en }">
                    <h3 class="text-primary"> [{{ client_code }}] {{ company_name_cn }} {{ company_name_en }}</h3>
                </template>
            </v-select>
            <h4 class=" mt-2">

            </h4>
            <b-col cols="12" class="mt-5">

                <b-button variant="primary" v-b-modal.modal-lg>
                    <feather-icon icon="PlusIcon" class="mr-50" />
                    <span class="text-nowrap">項目</span>
                </b-button>
                <b-button variant="primary" v-b-modal.modal-contract>
                    <feather-icon icon="PlusIcon" class="mr-50" />
                    <span class="text-nowrap">合約</span>
                </b-button>
             <!--    <b-button variant="primary" :to="{ name: 'quotations-add', params: { client_id: clientData.id } }">
                    <feather-icon icon="PlusIcon" class="mr-50" />
                    <span class="text-nowrap">報價單</span>
                </b-button> -->
                <b-button variant="primary" :to="{ name: 'invoices-add', params: { client_id: clientData.id } }">
                    <feather-icon icon="PlusIcon" />
                    <span class="text-nowrap">發票</span>
                </b-button>

                <b-button variant="primary" :to="{ name: 'clients-list' }">
                    <span class="text-nowrap">客戶列表</span>
                </b-button>

            </b-col>
        </b-card-body>

    </b-card>
</template>

<script>
import { BCard, BCardBody, BCardText, BRow, BCol, BButton } from "bootstrap-vue"
import vSelect from "vue-select";

export default {
    components: {
        BCard,
        BCardBody,
        BCardText,
        BRow,
        BCol,
        BButton,
        vSelect
    },
    methods: {
        changeClient(val) {
            this.$router.push({ name: 'clients-detail', params: { id: val } })
            this.$emit("refetch-data");
        },
        clientSearch(options, search) {
            var returnOptions = options.filter(item => {
                return item.client_code.toLowerCase().includes(search.toLowerCase()) || item.company_name_cn.toLowerCase().includes(search.toLowerCase()) || item.company_name_en.toLowerCase().includes(search.toLowerCase())
            })
            return returnOptions
        }
    },
    props: {
        clientData: {
            type: Object,
            required: true,
        },
        clientOptions: {
            type: Array,
            required: true,
        },
    }
}

</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style scoped>
.client-header {
    background-size: cover;
    min-height: 150px;
}

.btn-primary {
    opacity: 0.6;
    margin: 10px;
}

.btn-primary:hover {
    opacity: 1;
}

@media only screen and (max-width: 600px) {
    .btn-primary {
        width: 100%;

    }
}
</style>