import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ability from "@/libs/acl/ability";

export default function useProjectsList() {
  //User toast
  const toast = useToast();

  const refProjectListTable = ref(null);

  const tableProjectColumns = [
    { key: "project_id", label: "項目編號", sortable: true },
    { key: "client_id", label: "客戶編號", sortable: true },
    { key: "project_short_name", label: "項目縮寫名稱", sortable: true },
    { key: "project_name", label: "項目名稱", sortable: true },
    { key: "client.company_name_cn", label: "客戶名稱" },
    { key: "contract.contract_code", label: "合約編號" },
    { key: "application_codes", label: "項目服務", sortable: true },
    { key: "project_stage", label: "狀態" },
    { key: "file", label: "檔案" },
    { key: "actions", label: "操作" },
  ];

  const perPage = ref(10);
  const totalProjects = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const client_id = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refProjectListTable.value
      ? refProjectListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProjects.value,
    };
  });

  const refetchData = () => {
    refProjectListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery], () => {
    refetchData();
  });

  const fetchProjects = (ctx, callback) => {
    store
      .dispatch("project/fetchProjects", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        const { projects, total } = response.data;
        callback(projects);
        totalProjects.value = total;
      })
      .catch((error) => {
        console.log(error);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching project list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchProjects,
    tableProjectColumns,
    perPage,
    currentPage,
    totalProjects,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refProjectListTable,
    ability,
    refetchData,
  };
}
