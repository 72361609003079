<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar
              ref="refCalendar"
              :options="calendarOptions"
              class="full-calendar"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BAlert, BRow, BCol } from "bootstrap-vue";
import FullCalendar from "@fullcalendar/vue";
import store from "@/store";
import { ref, onMounted } from "@vue/composition-api";
import useCalendar from "./useCalendar";
import clientStoreModule from "../../clientStoreModule";

export default {
  components: {
    FullCalendar,
    BAlert,
    BRow,
    BCol,
  },
  props: {
    trigger: {
      type: Boolean,
    },
    dataTrigger: {
      type: Boolean,
    },
    clientId: {},
  },
  watch: {
    clientId: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.clientFilter = newVal;
        }
      },
    },
  },
  setup() {
    const APP_CLIENT_STORE_MODULE_NAME = "app-client";
    if (!store.hasModule(APP_CLIENT_STORE_MODULE_NAME))
      store.registerModule(APP_CLIENT_STORE_MODULE_NAME, clientStoreModule);

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      isEventHandlerSidebarActive,
      calendarOptions,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      fetchEvents,
      // FIlter,
      clientFilter,
      tags,
      selectAll,
      changeView,
    } = useCalendar();

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      isEventHandlerSidebarActive,
      calendarOptions,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      fetchEvents,
      // FIlter,
      clientFilter,
      tags,
      selectAll,
      changeView,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
