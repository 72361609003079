<template>
  <div>
    <project-list-add-new
      :is-add-new-project-sidebar-active.sync="isAddNewProjectSidebarActive"
      :id="id"
      :data="project"
      :projectstage-options="projectstageOptions"
      :application-options="applicationOptions"
      :document-type-options="documentTypeOptions"
      :contract-options="contractOptions"
      @refetch-data="refetchData"
    />
    <modal-stage
      :project-data="project"
      :projectstage-options="projectstageOptions"
      @refetch-data="refetchData"
    />
    <modal-application
      :project-data="project"
      :application-options="applicationOptions"
      @refetch-data="refetchData"
    />

    <b-card no-body class="mb-0">
      <b-table
        ref="refProjectListTable"
        class="position-relative"
        :items="projectData"
        responsive
        :fields="tableProjectColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
        <!-- Column: Application Items -->
        <template #cell(application_codes)="data">
          <div
            v-for="application_code in data.item.application_codes"
            :key="application_code.code_name"
            class="text-capitalize"
            @click="openApplicationModal(data.item)"
          >
            {{ application_code.code_name }}
          </div>
        </template>

        <template #cell(client_id)="data">
          <b-link
            :to="{
              name: 'clients-detail',
              params: { id: data.item.client_id },
            }"
            >{{ data.item.client.client_code }}</b-link
          >
        </template>

        <template #cell(project_stage)="data">
          <b-badge
            class="text-capitalize"
            v-if="data.item.project_stage"
            @click="openStageModal(data.item)"
            :style="{
              background: data.item.project_stage.background_color,
              color: data.item.project_stage.font_color,
            }"
          >
            {{ data.item.project_stage.stage }}
          </b-badge>
        </template>

        <!-- Column: Project Stage -->
        <template #cell(project_stage_id)="data">
          <b-badge
            v-if="data.item.project_stage"
            class="text-capitalize"
            :style="{
              background: data.item.project_stage.background_color,
              color: data.item.project_stage.font_color,
            }"
            @click="openStageModal(data.item)"
          >
            {{ data.item.project_stage.stage }}
          </b-badge>
        </template>

        <!-- Column: Created at -->
        <template #cell(created_at)="data">
          {{ getDate(data.item.created_at) }}
        </template>
        <template #cell(application_remark)="data">
          <div v-html="data.item.application_remark"></div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="handleEdit(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSidebar,
  BOverlay,
  BForm,
  BFormGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import axiosIns from "@/libs/axios";
import projectStoreModule from "@/views/project/projectStoreModule";
import Ripple from "vue-ripple-directive";

import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
import ModalApplication from "@/views/project/modal/ModalApplication.vue";
import ModalStage from "@/views/project/modal/ModalStage.vue";
import ProjectListAddNew from "@/views/project/projects-list/ProjectListAddNew.vue";

export default {
  data() {
    return {
      id: 0,
      project: {},
    };
  },
  directives: {
    Ripple,
  },
  components: {
    ProjectListAddNew,
    ModalApplication,
    ModalStage,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSidebar,
    BForm,
    BFormGroup,
    BOverlay,
    vSelect,
    quillEditor,
  },
  methods: {
    resetProjectData() {
      this.project = {
        id: 0,
        is_audit: true,
        audit_date: "",
        audit_remark: "",
      };
    },
    handleEdit(item) {
      this.resetProjectData();
      this.project = { ...item };
      this.id = item.id;
      this.isAddNewProjectSidebarActive = true;
    },
    openStageModal(item) {
      this.project = { ...item };
      this.$bvModal.show("modal-stage");
    },
    openApplicationModal(item) {
      this.project = { ...item };
      this.$bvModal.show("modal-application");
    },
    updateSideBar(val) {
      this.isAddNewProjectSidebarActive = val;
    },
    hide() {
      this.isAddNewProjectSidebarActive = false;
    },
    resetForm() {
      this.project = {
        id: 0,
        application_codes: [],
        application_code_id: [],
        application_remark: "",
      };
    },
    editProject(item) {
      this.project.application_code_id = [];
      this.project.id = item.id;
      this.project.application_codes = item.application_codes;
      this.project.application_remark = item.application_remark;
      item.application_codes.forEach((code) => {
        this.project.application_code_id.push(code.id);
      });
      this.isAddNewProjectSidebarActive = true;
    },

    getDate(str) {
      return str.substring(0, 10);
    },
    refetchData() {
      this.$emit("refetch-data");
    },
    getNumberFormat(amount) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HKD",
        minimumFractionDigits: 0,
      });
      return formatter.format(amount);
    },

    onSubmit() {
      this.$swal({
        title: "確認儲存?",
        text: "",
        showCancelButton: true,
        confirmButtonText: "儲存",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          this.$store
            .dispatch("app-client/updateProject", this.project)
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.loading = false;
              this.hide();
              this.$emit("refetch-data");
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  props: {
    projectData: {
      type: Array,
      required: true,
    },
    tableProjectColumns: {
      type: Array,
      required: true,
    },
    applicationOptions: {
      type: Array,
      required: true,
    },
    projectstageOptions: {
      type: Array,
      required: true,
    },
    contractOptions: {
      type: Array,
      required: true,
    },
    documentTypeOptions: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const PROJECT_STORE_MODULE_NAME = "project";
    const isSortDirDesc = ref(true);
    const isAddNewProjectSidebarActive = ref(false);
    const project = ref({
      id: 0,
      application_codes: [],
      application_code_id: [],
      application_remark: "",
    });

    if (!store.hasModule(PROJECT_STORE_MODULE_NAME))
      store.registerModule(PROJECT_STORE_MODULE_NAME, projectStoreModule);

    onUnmounted(() => {
      if (store.hasModule(PROJECT_STORE_MODULE_NAME))
        store.unregisterModule(PROJECT_STORE_MODULE_NAME);
    });

    const loading = ref(false);

    const blankProjectData = {
      id: 0,
      application_codes: [],
      application_remark: "",
    };
    const button_text = ref("Edit");

    return {
      isSortDirDesc,
      isAddNewProjectSidebarActive,
      project,
      button_text,
      loading,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.badge {
  display: block;
  margin: 5px;
  max-width: 200px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.badge {
  background-color: $primary;
  opacity: 0.8;
}
</style>
