<template>
  <b-sidebar id="add-new-project-sidebar" :visible="isAddNewProjectSidebarActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-project-sidebar-active', val)">
    <template #default="{ hide }">
      <div class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        ">
        <h5 class="mb-0">修改項目資料</h5>
        <div>
          <feather-icon class="ml-1 cursor-pointer" icon="SaveIcon" size="18" style="color: green" @click="onSubmit" />
          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>
      </div>

      <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
        <!-- Body -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <b-form-group class="" label="狀態" label-for="project_stage_id">
              <v-select v-model="projectData.project_stage_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="projectstageOptions" label="stage" :reduce="(option) => option.id" :clearable="false">
                <template #option="{ stage, background_color }">
                  <div class="rounded-circle d-inline-block mr-50 bg-primary" :style="{
                    background: background_color + ' !important',
                    height: '10px',
                    width: '10px',
                  }" />
                  <span> {{ stage }}</span>
                </template>

                <template #selected-option="{ stage, background_color }">
                  <div class="rounded-circle d-inline-block mr-50 bg-primary" :style="{
                    background: background_color + ' !important',
                    height: '10px',
                    width: '10px',
                  }" />
                  <span> {{ stage }}</span>
                </template>
              </v-select>
            </b-form-group>
            <b-form-group  label="合約" label-for="contract_id">
              <v-select v-model="projectData.contract_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="contractOptions.filter(item => item.client_id == projectData.client_id)" label="contract_code" :reduce="(option) => option.id" :clearable="true"></v-select>
            </b-form-group>
            <b-form-group class="required" label="項目縮寫名稱" label-for="project_short_name">
              <b-form-input v-model="projectData.project_short_name" class="" placeholder="項目縮寫名稱" />
            </b-form-group>

            <b-form-group class="required" label="項目名稱" label-for="project_name">
              <b-form-input v-model="projectData.project_name" placeholder="項目名稱" />
            </b-form-group>

            <b-form-group class="required" label="項目服務" label-for="application_code_ids">
              <v-select multiple v-model="projectData.application_code_ids"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="applicationOptions" label="code_name"
                :reduce="(option) => option.id" :clearable="false">
              </v-select>
            </b-form-group>
            <div class="d-flex justify-content-between align-items-center">
              <h5>額外資料</h5>
              <div class="d-flex justify-content-center align-items-center">
                <b-form-input v-model="field" type="text" class="mr-1" placeholder="輸入想新增的資料欄名" />
                <b-button v-if="field" style="width: 120px; padding:10px;" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm" variant="primary" @click="addInformation">
                  新增資料
                </b-button>
              </div>
            </div>

            <b-form-group class="" :label="information.field" :label-for="`information_${index}`"
              v-for="(information, index) in projectData.information" :key="`information_${index}`">
              <b-input-group>
                <b-form-input :id="`information_${index}`" type="text" :placeholder="`輸入${information.field}...`"
                  v-model="information.value" />
                <b-input-group-append is-text>
                  <feather-icon icon="TrashIcon" class="cursor-pointer text-danger" @click="removeInformation(index)" />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <hr />
            <b-col cols="12" md="12" class="mb-1">
              <div class="d-flex justify-content-between align-items-center">
                <h5>需求文件提示設置</h5>
                <div class="d-flex justify-content-center align-items-center">
                  <v-select class="document-selector" v-model="document_type_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="filteredDocuments" label="name"
                    :reduce="(option) => option.id" :clearable="true">
                  </v-select>
                  <b-button v-if="document_type_id" style="width: 120px; padding:10px;"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary" @click="addDocument">
                    新增文件
                  </b-button>
                </div>
              </div>
            </b-col>
            <b-col cols="12" md="12" v-for="(document, index) in projectData.documents" :key="`document_${index}`">
              <b-form-group class="mt-1" :label="document.field" :label-for="`document_${index}`">
                <b-input-group>
                  <b-form-checkbox v-model="document.is_received" class="mr-1 mt-50" name="is_received"
                    inline>已接收</b-form-checkbox>
                  <b-form-input :id="`document_${index}`" type="text"
                    :value="documentTypeOptions && documentTypeOptions.find(option => option.id == document.document_type_id).name"
                    disabled />
                  <b-input-group-append is-text>
                    <feather-icon icon="TrashIcon" class="cursor-pointer text-danger" @click="removeDocument(index)" />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>


            <b-form-group label="備註" label-for="remarks">
              <quill-editor v-model="projectData.remarks" />
            </b-form-group>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
                儲存
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
                @click="hide">
                取消
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
  </b-sidebar>
</template>
<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BOverlay,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, watch } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { useToast } from "vue-toastification/composition";
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormTextarea,
    BOverlay,
    BFormDatepicker,
    quillEditor,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewProjectSidebarActive",
    event: "update:is-add-new-project-sidebar-active",
  },
  props: {
    documentTypeOptions: {
      type: Array,
      required: true,
    },
    applicationOptions: {
      type: Array,
      required: true,
    },
    isAddNewProjectSidebarActive: {
      type: Boolean,
      required: true,
    },
    projectstageOptions: {
      type: Array,
      required: true,
    },
    contractOptions:{
      type: Array,
      required: true
    },
    id: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      field: '',
      document_type_id: null
    };
  },
  methods: {
    addDocument() {
      if (!this.projectData.documents) {
        this.$set(this.projectData, 'documents', [])
      }

      let name = this.documentTypeOptions && this.documentTypeOptions.find(option => option.id == this.document_type_id).name

      const newDoc = { document_type_id: this.document_type_id, is_received: false, remark: '', name }

      this.$set(this.projectData.documents, this.projectData.documents.length, newDoc)

      this.document_type_id = null
    },
    removeDocument(index) {
      this.projectData.documents.splice(index, 1)
    },
    addInformation() {
      if (!this.projectData.information) {
        this.projectData.information = []
      }
      this.projectData.information.push(JSON.parse(JSON.stringify({ field: this.field, value: '' })))
      this.field = '';
    },
    removeInformation(index) {
      this.projectData.information.splice(index, 1)

    },
    hide() {
      this.isAddNewProjectSidebarActive = false;
    },
    onSubmit() {
      this.$swal({
        title: "確定修改？",
        text: "",
        showCancelButton: true,
        confirmButtonText: "修改",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          this.$store
            .dispatch("project/updateProject", this.projectData)
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.loading = false;
              this.hide();
              this.$emit("refetch-data");
              this.$emit("refetch-statistic");
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  computed: {
    filteredDocuments() {

      if (!this.projectData.documents) {
        console.log({ documentTypeOptions: this.documentTypeOptions })
        return this.documentTypeOptions
      }
      return this.documentTypeOptions.filter(option => !this.projectData.documents.some(document => document.document_type_id == option.id))
    }
  },
  setup(props, { emit }) {
    const blankProjectData = {
      id: 0,
    };
    const loading = ref(false);

    const projectData = ref(JSON.parse(JSON.stringify(blankProjectData)));

    const resetprojectData = () => {
      projectData.value = JSON.parse(JSON.stringify(blankProjectData));
    };

    watch(
      () => [props.id, props.isAddNewProjectSidebarActive],
      () => {
        if (props.id > 0 && props.isAddNewProjectSidebarActive) {
          projectData.value = { ...props.data };
        } else {
        }
      }
    );

    const editorOption = {
      modules: {
        toolbar: "#quill-toolbar-project",
      },
      placeholder: "remark",
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetprojectData);

    return {
      projectData,
      loading,
      refFormObserver,
      getValidationState,
      resetForm,
      editorOption,
    };
  },
};
</script>

<style>
.ql-editor {
  height: 300px;
}
</style>
