import axiosIns from '@/libs/axios'

export default {
    namespaced: true,

    actions: {
        fetchPaymentAccounts(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns.get('/payment_account/list', {params: queryParams}).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        fetchPaymentAccount(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axiosIns.get(`/payment_account/${id}`).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        addPaymentAccount(ctx, data) {
            return new Promise((resolve, reject) => {
                axiosIns.post('/payment_account', data).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        fetchQuotations(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns.get('/quotation/list', {params: queryParams}).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        fetchPaymentMethods(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns.get('/quotation/getPaymentMethods', {params: queryParams}).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        fetchQuotation(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axiosIns.get(`/quotation/${id}`).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        deleteQuotation(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns.delete('/quotation', {params:queryParams})
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        fetchQuotationInformation(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns.get('/invoice/information', {params: queryParams}).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        addQuotation(ctx, data) {
            return new Promise((resolve, reject) => {
                axiosIns.post('/quotation', data).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        addQuotationPayment(ctx, data) {
            return new Promise((resolve, reject) => {
                axiosIns.post('/quotation/payment', data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        fetchUsers(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns.get('/user/list', {params: queryParams}).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        fetchClients(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns.get('/client/list', {params: queryParams}).then(response => resolve(response)).catch(error => reject(error))
            })
        }
    }
}
