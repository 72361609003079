var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-modal',{attrs:{"id":"modal-stage","ok-title":"修改","cancel-title":"取消","cancel-variant":"outline-secondary","size":"sm","centered":"","title":"修改狀態","no-close-on-backdrop":""},on:{"ok":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-overlay',{attrs:{"show":_vm.loading,"spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"sm"}},[_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-form-group',{attrs:{"label":"狀態","label-for":"project_stage_id"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.projectstageOptions,"label":"stage","reduce":function (option) { return option.id; },"clearable":true},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var stage = ref.stage;
var background_color = ref.background_color;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50 bg-primary",style:({
                  background: background_color + ' !important',
                  height: '10px',
                  width: '10px',
                })}),_c('span',[_vm._v(" "+_vm._s(stage))])]}},{key:"selected-option",fn:function(ref){
                var stage = ref.stage;
                var background_color = ref.background_color;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50 bg-primary",style:({
                  background: background_color + ' !important',
                  height: '10px',
                  width: '10px',
                })}),_c('span',[_vm._v(" "+_vm._s(stage))])]}}],null,true),model:{value:(_vm.projectData.project_stage_id),callback:function ($$v) {_vm.$set(_vm.projectData, "project_stage_id", $$v)},expression:"projectData.project_stage_id"}})],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }