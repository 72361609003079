<template>
    <b-card>
        <!--<div id="ckfinder-widget"></div>-->
        <file-manager v-bind:settings="settings" ref="file"></file-manager>
    </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import store from "@/store";

export default {
    data() {
        return {
            settings: {
                // axios headers
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
                    'client-code': this.clientCode,
                },
                //root: process.env.VUE_APP_IMAGE_URL,
                baseUrl: process.env.VUE_APP_LFM_AXIOS_BASE_URL, // overwrite base url Axios
                windowsConfig: 2, // overwrite config
                lang: 'en', // set language
            },
            jump_into_the_file:false

        }
    },
    components: {
        BCard,
    },
    methods: {
        initial_ckfinder() {

            CKFinder.widget("ckfinder-widget", {
                id: this.clientData.client_code,
                pass: "id",
                width: "100%",
                //baseUrl:process.env.VUE_APP_IMAGE_URL,
                height: 700,
                onInit: function(finder) {
                    //console.log('finder',finder)
                    finder.on("files:choose", function(evt) {
                       // console.log('files:choose')
                        var file = evt.data.files.first();
                        var output = document.getElementById(elementId);
                        output.value = file.getUrl();
                        
                    });

                    finder.on("file:choose:resizedImage", function(evt) {
                        //console.log('file:choose:resizedImage')
                        var output = document.getElementById(elementId);
                        output.value = evt.data.resizedUrl;
                    });

                    finder.on("file:preview", function(evt) {
                        //console.log('file:preview')
                        // Check if file extension is supported your plugin
                        //console.log('evt', evt)
                        if (evt.data.extension === "pdf") {
                            // Stop event propagation so no other listener is fired
                            evt.stop();
                            // Add data for template
                            //let url = evt.data.url.replace('http://localhost',process.env.VUE_APP_IMAGE_URL)

                            evt.data.templateData = {
                                url: evt.data.url
                            };
                            // Add template for file preview rendering
                            evt.data.template = '<iframe style="height:100vh;width:90vw;" src="{{= it.url}}"></iframe>';
                        }
                    });
                },
            });

        },
    },
    computed: {
        client_code() {
            return this.clientData.client_code
        },
        client_folder(){
            let client_folder = document.querySelector('[title="'+this.clientCode+'"]');
            console.log('client_folder', client_folder)
            return client_folder
        },
        directory() {
            return this.$store.getters['fm/tree/directories'];
        }
    },
    watch: {
        client_code() {
            console.log('client_code', this.client_code)
            this.clientCode = this.client_code
            if (this.client_code != null) {
                //this.initial_ckfinder()
            }
        },
        directory(){
            /*
            let filter_directory = this.directory.filter(item=>item.basename == this.client_code)
            if(filter_directory.length>0 && !this.jump_into_the_file && this.directory != null)
            {
                let folder = document.querySelector('[title="'+this.client_code+'"]')
                // Simulate the double click event
                var clickEvent  = document.createEvent ('MouseEvents');
                clickEvent.initEvent('dblclick', true, true);
                folder.dispatchEvent(clickEvent);
                this.jump_into_the_file = true
            }
            */
        }
    },
    props: {
        clientData: {
            type: Object,
            required: true,
        },
        clientCode:{
          type: String,
          required: true
        }
    },
    mounted() {
       let button = document.querySelector('[title="Grid"]')
       button.click()
       console.log('file',this.$refs.file)
       
    },
};


    

</script>

<style>
.fm .fm-body{
  min-height:500px;
}
</style>