<template>
  <div>
    <modal-pdf id="-client-contract-list" :pdfUrl="pdfUrl" />
    <b-sidebar id="add-new-contract-sidebar" :visible="isAddNewContractSidebarActive" bg-variant="white"
      sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
      @change="(val) => updateSideBar(val)">
      <template #default="{ hide }">
        <!-- Header -->
        <div class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          ">
          <h5 class="mb-0">
            上傳簽署資料及已簽署合約
          </h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>

        <!-- Body -->
        <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
          <!-- Form -->
          <b-form class="p-2" @submit.prevent="onSubmit" @reset.prevent="resetForm">

            <!-- Description -->
            <b-form-group class="signed_at required" label="簽約日期" label-for="signed_at">
              <b-form-datepicker id="signed_at" placeholder="Choose a date" local="en" v-model="contract.signed_at"
                :date-format-options="{
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }" />
            </b-form-group>
            <b-form-group label="上傳已簽署合約">
              <el-upload class="avatar-uploader" list-type="picture-card" ref="upload" :action="uploadUrl"
                :headers="headerObj" name="image_file" :file-list="fileList" multiple :limit="1" :data="contract"
                :on-change="onChange" :on-remove="toggleUpload" :before-remove="beforeRemove" :auto-upload="false"
                :class="{ hideUpload: !showUpload }">
                <img v-if="contract.image_path && false" :src="getImgPath(contract.image_path)" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </b-form-group>
            <b-form-group label="Remark" label-for="remark">
              <b-form-textarea id="remark" v-model="contract.remark" trim />
            </b-form-group>

            <!-- Form Actions -->

            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
                {{ button_text }}
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
                @click="hide">
                Cancel
              </b-button>
            </div>
          </b-form>
        </b-overlay>
      </template>
    </b-sidebar>

    <b-sidebar id="edit-contract-sidebar" :visible="isEditContractSidebarActive" bg-variant="white"
      sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetEditForm"
      @change="(val) => updateSideBar(val)">
      <template #default="{ hide }">
        <!-- Header -->
        <div class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          ">
          <h5 class="mb-0">
            修改合約
          </h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hideEditSideBar" />
        </div>

        <!-- Body -->
        <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
          <!-- Form -->
          <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
            <b-form class="p-2" @submit.prevent="handleSubmit(onSubmitEdit)" @reset.prevent="resetEditForm">
              <b-row>
                <!-- Form Actions -->
                <b-col cols="12" md="12">
                  <validation-provider #default="validationContext" name="合約名稱" rules="required">
                    <b-form-group class="required" label="合約名稱" label-for="contract_name">
                      <b-form-input id="contract_name" v-model="contractData.contract_name"
                        :state="getValidationState(validationContext)" />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Field: 合約日期 -->
                <b-col cols="12" md="12">
                  <validation-provider #default="validationContext" name="合約日期" rules="required">
                    <b-form-group class="contract_date required" label="合約日期" label-for="contract_date">
                      <b-form-datepicker id="contract_date" :state="getValidationState(validationContext)"
                        placeholder="Choose a date" local="en" v-model="contractData.contract_date"
                        :date-format-options="{
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }" />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Field: 合約開始日期 -->
                <b-col cols="12" md="12">
                  <b-form-group class="start_date" label="合約開始日期" label-for="start_date">
                    <b-form-datepicker id="start_date" placeholder="Choose a date" local="en"
                      v-model="contractData.start_date" :date-format-options="{
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }" />
                  </b-form-group>
                </b-col>
                <!-- Field: 合約完結日期 -->
                <b-col cols="12" md="12">
                  <b-form-group class="end_date" label="合約完結日期" label-for="end_date">
                    <b-form-datepicker id="end_date" placeholder="Choose a date" local="en"
                      v-model="contractData.end_date" :date-format-options="{
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="12">
                  <b-form-group class="" label="公司名稱(中文)" label-for="company_name_cn">
                    <b-form-input id="company_name_cn" v-model="contractData.company_name_cn" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="12">
                  <b-form-group class="" label="公司名稱(英文)" label-for="company_name_en">
                    <b-form-input id="company_name_en" v-model="contractData.company_name_en" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="12">
                  <b-form-group class="" label="登記/通訊地址" label-for="br_address">
                    <b-form-input id="br_address" v-model="contractData.br_address" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="12">
                  <b-form-group class="" label="商業登記號碼" label-for="br_no">
                    <b-form-input id="br_no" v-model="contractData.br_no" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="12">
                  <b-form-group class="" label="聯絡人名稱" label-for="contact_name">
                    <b-form-input id="contact_name" v-model="contractData.contact_name" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="12">
                  <b-form-group class="" label="聯絡人職位" label-for="contact_title">
                    <b-form-input id="contact_title" v-model="contractData.contact_title" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="12">
                  <b-form-group class="" label="聯絡人電話" label-for="contact_phone">
                    <b-form-input id="contact_phone" v-model="contractData.contact_phone" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="12">
                  <b-form-group class="" label="身份證號碼" label-for="id_number">
                    <b-form-input id="id_number" v-model="contractData.id_number" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="12">
                  <b-form-group class="" label="備註" label-for="remark">
                    <b-form-textarea id="remark" v-model="contractData.remark" trim />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="12" v-if="contract.is_signed">
                  <b-form-group label="合約狀態" label-for="is_signed" label-cols-md="3">
                    <b-form-checkbox :checked="contractData.is_signed" name="is_signed" switch inline
                      v-model="contractData.is_signed" value="1">
                      {{ contractData.is_signed ? "已簽署" : "未簽署" }}
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="12" v-if="contract.is_signed">
                  <b-form-group class="signed_at" label="合約簽署日期" label-for="signed_at">
                    <b-form-datepicker id="signed_at" placeholder="Choose a date" local="en"
                      v-model="contractData.signed_at" :date-format-options="{
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }" />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="12">
                  <b-form-group class="" label="銷售顧問" label-for="sale_id">
                    <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    v-model="contractData.sale_id"
                    :options="userOptions"
                    label="name"
                    class="w-100"
                    :reduce="(val) => val.id"
                  />
                  </b-form-group>
              </b-col>

                <b-col cols="12" md="12">
                  <b-form-group label="合約狀態" label-for="is_completed" label-cols-md="3">
                    <b-form-checkbox :checked="contractData.is_completed" name="is_completed" switch inline
                      v-model="contractData.is_completed" value="1">
                      {{ contractData.is_completed ? "已完成" : "未完成" }}
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="d-flex mt-2">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
                  {{ button_text }}
                </b-button>
                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
                  @click="hide">
                  取消
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-overlay>
      </template>
    </b-sidebar>

    <b-overlay
    :show="loading"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card no-body class="mb-0">
      <b-table ref="refContractListTable" class="position-relative" :items="contracts" responsive
        :fields="tableContractColumns" primary-key="id" show-empty empty-text="No matching records found">
        <!-- Column: Projects-->
        <template #cell(projects)="data">
          <b-badge v-for="project in data.item.projects" :key="project.id" class="text-capitalize">
            {{ project.project_id }}
          </b-badge>
        </template>
        <!-- Column: Invoices -->
        <template #cell(invoices)="data">
          <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-success" class="btn-icon" target="_blank"
            v-for="invoice in data.item.invoices" :key="invoice.id"
            :to="{ name: 'invoices-preview', params: { id: invoice.id } }">
            <feather-icon icon="FileTextIcon" />
          </b-button>
        </template>


        <template #cell(is_signed)="data">
          <feather-icon v-if="data.item.is_signed" icon="CheckIcon" size="18" class="mr-50 text-success" />
        </template>
        <template #cell(is_completed)="data">
          <feather-icon v-if="data.item.is_completed" icon="CheckIcon" size="18" class="mr-50 text-success" />
        </template>


        <!-- Column: Export -->
        <template #cell(contract)="data">
          <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-primary" class="btn-icon" target="_blank"
            @click="downloadContract(data.item.id)">
            <feather-icon icon="FileTextIcon" />
          </b-button>
        </template>
        <!-- Column: Export -->
        <template #cell(created_at)="data">
          {{ getCorrectDateTime(data.item.created_at) }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL" >
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item @click="sign(data.item)" v-if="!data.item.is_signed">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">簽署</span>
            </b-dropdown-item>
            <b-dropdown-item @click="editContract(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">修改</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSidebar,
  BOverlay,
  BForm,
  BFormGroup,
  BFormDatepicker,
  BFormTextarea,
  BFormCheckbox,
  BFormInvalidFeedback
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";

import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import ModalPdf from '@/layouts/components/ModalPdf.vue';
import clientStoreModule from "@/views/client/client/clientStoreModule";

export default {
  data(){
    return{
      pdfUrl: ""
    }
  },
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSidebar,
    BForm,
    BFormGroup,
    BOverlay,
    vSelect,
    quillEditor,
    BFormDatepicker,
    BFormTextarea,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    ModalPdf
  },
  methods: {
    downloadContract(id) {
      this.loading = true;
      this.$store
        .dispatch("app-client/downloadContract", { id })
        .then((response) => {
          console.log('response', response)
          const filepdf = new Blob([response.data], {
            type: "application/pdf",
          });
          const fileURL = URL.createObjectURL(filepdf);
          this.pdfUrl = fileURL;
          this.$bvModal.show("modal-pdf-client-contract-list");
          // console.log(this.pdfUrl)
          this.loading = false;
        })
        .catch((error) => {
          console.log({ error });
          this.loading = false;
        });
    },
    toggleUpload() {
      this.showUpload = !this.showUpload;
    },
    beforeRemove(file) {
      if (
        file.url ==
        process.env.VUE_APP_IMAGE_URL + this.contract.image_path
      ) {
        this.removeExistingPic = true;
        this.contract.image_path = null;
      }
    },
    onChange(file, fileLists) {
      this.showUpload = !this.showUpload;
      console.log(file.raw);
      this.contract.file = file.raw;
    },
    updateSideBar(val) {
      this.isAddNewContractSidebarActive = val;
    },
    updateEditSideBar(val) {
      console.log('updateEditSideBar', val)
      this.isEditContractSidebarActive = val;
    },
    hideEditSideBar() {
      this.isEditContractSidebarActive = false;
    },
    hide() {
      this.isAddNewContractSidebarActive = false;
    },
    resetEditForm() {
      this.isEditContractSidebarActive = false;
    },
    resetForm() {
      this.contract = {
        id: 0,
        application_codes: [],
        payment_method_id: [],
        application_remark: "",
      };
    },
    sign(item) {
      console.log("sign", item);
      this.contract.contract_id = item.contract_id;
      this.contract.id = item.id;
      this.contract.signed_at = "";
      this.contract.remark = "";
      this.isAddNewContractSidebarActive = true;
    },
    editContract(item) {
      this.contractData = { ...item }
      this.contract = {...item}
      this.isEditContractSidebarActive = true;
    },
    getDate(str) {
      return str.substring(0, 10);
    },
    getNumberFormat(amount) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HKD",
        minimumFractionDigits: 0,
      });
      return formatter.format(amount);
    },
    onSubmitEdit() {
      this.$swal({
        title: "確定修改合約?",
        showCancelButton: true,
        confirmButtonText: "修改",
        cancelButtonText: '取消',
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.loading = true
          console.log(this.contractData)
          this.$store.dispatch("app-client/addContract", this.contractData)
            .then((response) => {
              this.loading = false
              this.$swal({
                text: response.data.message,
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                showClass: {
                  popup: 'animate__animated animate__bounceIn',
                },
                buttonsStyling: false,
              })
              this.$emit('refetch-data')
              if (this.contractData.id == 0) {
                // Return to client list
                this.hideEditSideBar();
              }
            }).catch((error) => {
              this.loading = false
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                showClass: {
                  popup: 'animate__animated animate__bounceIn',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    },
    onSubmit() {
      this.$swal({
        title: "確認提交簽署記錄?",
        text: "",
        showCancelButton: true,
        confirmButtonText: "提交",
        cancelButtonText: '取消',
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          let formData = new FormData();
          formData.append("file", this.contract.file);
          formData.append("id", this.contract.id);
          formData.append("signed_at", this.contract.signed_at);
          formData.append("remark", this.contract.remark);
          this.$store
            .dispatch("app-client/signContract", formData)
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.loading = false;
              this.hide();
              this.$emit("refetch-data");
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  props: {
    contracts: {
      type: Array,
      required: true,
    },
    tableContractColumns: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const APP_CLIENT_STORE_MODULE_NAME = "app-client";

    if (!store.hasModule(APP_CLIENT_STORE_MODULE_NAME))
      store.registerModule(APP_CLIENT_STORE_MODULE_NAME, clientStoreModule);
    
    const showUpload = ref(true);
    const isSortDirDesc = ref(true);
    const isAddNewContractSidebarActive = ref(false);
    const isEditContractSidebarActive = ref(false);
    const uploadUrl = ref(process.env.VUE_APP_SERVICE_URL + "/contract");
    const headerObj = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };
    const fileList = ref([]);
    const contract = ref({
      id: 0,
      application_codes: [],
      payment_method_id: [],
      application_remark: "",
    });

    const loading = ref(false);

    const blankContractData = {
      id: 0
    };
    const button_text = ref("提交");

    const contractData = ref({ ...blankContractData });

    const resetContractData = () => {
      contractData.value = JSON.parse(JSON.stringify(blankContractData));
    };

    const { refFormObserver, getValidationState } =
      formValidation(resetContractData);


    const userOptions = ref([]);

    store
      .dispatch("app/fetchOptionList", { user: true})
      .then((response) => {
        userOptions.value = response.data.users;
      })
      .catch((error) => {
        console.log(error);
      });


    return {
      isSortDirDesc,
      isAddNewContractSidebarActive,
      isEditContractSidebarActive,
      contract,
      button_text,
      loading,
      showUpload,
      uploadUrl,
      headerObj,
      fileList,
      refFormObserver,
      getValidationState,
      contractData,
      userOptions
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.badge {
  display: block;
  margin: 5px;
  max-width: 200px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.badge {
  background-color: $primary;
  opacity: 0.8;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100%;
  height: 100%;
  line-height: 150px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
  border-radius: 0%;
}

.hideUpload>div {
  display: none;
}
</style>
