<template>
  <div>
    <modal-pdf id="-client-invoice-list" :pdfUrl="pdfUrl" />
    <b-sidebar id="add-new-invoice-sidebar" :visible="isAddNewInvoiceSidebarActive" bg-variant="white"
      sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
      @change="(val) => updateSideBar(val)">
      <template #default="{ hide }">
        <!-- Header -->
        <div class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          ">
          <h5 class="mb-0">Payment Detail - {{ invoice.invoice_number }} ({{ invoice.client_code }})</h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>

        <!-- Body -->
        <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
          <!-- Form -->
          <b-form class="p-2" @submit.prevent="onSubmit" @reset.prevent="resetForm">
            <b-form-group class="" label="應付金額" label-for="total_amount">
              <b-form-input id="total_amount" v-model="invoice.total_amount" disabled />
            </b-form-group>
            <b-form-group class="" label="已付金額" label-for="total_paid">
              <b-form-input id="total_paid" v-model="invoice.total_paid" disabled />
            </b-form-group>
            <b-form-group class="required" label="支付金額" label-for="payment_amount">
              <b-form-input id="payment_amount" v-model="invoice.payment_amount" />
            </b-form-group>
            <b-form-group class="required" label="支付ITEM" label-for="item" v-if="false">
              <b-form-input id="item" v-model="invoice.item" />
            </b-form-group>
            <b-form-group class="required" label="收據模版" label-for="receipt_template_id">
              <v-select v-model="invoice.receipt_template_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="receiptTemplateOptions" label="name" :reduce="(option) => option.id" :clearable="false">
              </v-select>
            </b-form-group>
            <b-form-group class="required" label="支付方法" label-for="payment_method_id">
              <v-select v-model="invoice.payment_method_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="paymentOptions" label="method_name" :reduce="(option) => option.id" :clearable="false">
              </v-select>
            </b-form-group>
            <!-- Description -->
            <b-form-group class="payment_date required" label="支付日期" label-for="payment_date">
              <b-form-datepicker id="payment_date" placeholder="Choose a date" local="en" v-model="invoice.payment_date"
                :date-format-options="{ 'year': 'numeric', 'month': 'numeric', 'day': 'numeric' }" />
            </b-form-group>
            <b-form-group label="收據">
              <el-upload class="avatar-uploader" list-type="picture-card" ref="upload" :action="uploadUrl"
                :headers="headerObj" name="image_file" :file-list="fileList" multiple :limit="1" :data="invoice"
                :on-change="onChange" :on-remove="toggleUpload" :before-remove="beforeRemove" :auto-upload="false"
                :class="{ hideUpload: !showUpload }">
                <img v-if="invoice.image_path && false" :src="getImgPath(invoice.image_path)" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>

            </b-form-group>
            <b-form-group label="支付備註" label-for="remark">
              <b-form-textarea v-model="invoice.payment_remark" />
            </b-form-group>

            <!-- Form Actions -->

            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
                儲存
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
                @click="hide">
                取消
              </b-button>
            </div>
          </b-form>
        </b-overlay>
      </template>
    </b-sidebar>
    <b-card no-body class="mb-0">
      <b-overlay :show="tableLoading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
      <b-table ref="refInvoiceListTable" class="position-relative" :items="invoiceData" responsive
        :fields="tableInvoiceColumns" primary-key="id" show-empty empty-text="No matching records found">

        <!-- Column: IS PAID -->
        <template #cell(is_paid)="data">
          <feather-icon v-if="data.item.total_paid >= data.item.total_amount" icon="CheckIcon" size="18"
            class="mr-50 text-success" />
        </template>
        <!-- Column: IS PAID -->
        <template #cell(is_audit)="data">
          <feather-icon v-if="data.item.is_audit" icon="CheckIcon" size="18" class="mr-50 text-success" />
        </template>

        <template #cell(invoice)="data">
          <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-primary" class="btn-icon" target="_blank"
            @click="downloadInvoice(data.item.id)">
            <feather-icon icon="FileTextIcon" />
          </b-button>
        </template>


        <template #cell(project)="data">
          <span v-if="data.item.project">{{
            data.item.project.project_id
            }}</span>
        </template>

        <!-- Column: Client Code -->
        <template #cell(client)="data">
          <b-link :to="{
            name: 'clients-detail',
            params: { id: data.item.client.id },
          }">{{ data.item.client.client_code }}</b-link>
        </template>

        <!-- Column: Total Amount -->
        <template #cell(total_amount)="data">
          {{ getNumberFormat(data.item.total_amount) }}
        </template>


        <!-- Column: Total Paid -->
        <template #cell(total_paid)="data">
          {{ getNumberFormat(data.item.total_paid) }}
        </template>

        <!-- Column: Created at -->
        <template #cell(created_at)="data">
          {{ getDate(data.item.created_at) }}
        </template>

        <!-- Column: Export -->
        <template #cell(export)="data">
          <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-success" class="btn-icon"
            :to="{ name: 'invoices-preview', params: { id: data.item.id } }">
            <feather-icon icon="FileTextIcon" />
          </b-button>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <b-dropdown-item
              v-if="ability.can('update', 'invoices') && !data.item.is_audit && data.item.deleted_at == null"
              :to="{ name: 'invoices-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">修改</span>
            </b-dropdown-item>
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item @click="payment(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">付款</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSidebar,
  BOverlay,
  BForm,
  BFormGroup,
  BFormDatepicker,
  BFormTextarea
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import axiosIns from "@/libs/axios";
import invoiceStoreModule from "@/views/invoice/invoiceStoreModule";
import Ripple from "vue-ripple-directive";

import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
import ModalPdf from '@/layouts/components/ModalPdf.vue';
import clientStoreModule from "@/views/client/client/clientStoreModule";
import ability from '@/libs/acl/ability'

export default {
  data() {
    return {
      pdfUrl: "",
      ability
    }
  },
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSidebar,
    BForm,
    BFormGroup,
    BOverlay,
    vSelect,
    quillEditor,
    BFormDatepicker,
    BFormTextarea,
    ModalPdf
  },
  methods: {
    downloadInvoice(id) {
      this.tableLoading = true;
      this.$store
        .dispatch("app-client/downloadInvoice", { id })
        .then((response) => {
          console.log('response', response)
          const filepdf = new Blob([response.data], {
            type: "application/pdf",
          });
          const fileURL = URL.createObjectURL(filepdf);
          this.pdfUrl = fileURL;
          this.$bvModal.show("modal-pdf-client-invoice-list");
          this.tableLoading = false;
        })
        .catch((error) => {
          console.log({ error });
          this.tableLoading = false;
        });
    },
    toggleUpload() {
      this.showUpload = !this.showUpload
    },
    beforeRemove(file) {
      if (file.url == process.env.VUE_APP_IMAGE_URL + this.invoice.image_path) {
        this.removeExistingPic = true
        this.invoice.image_path = null
      }
    },
    onChange(file, fileLists) {
      this.showUpload = !this.showUpload
      console.log(file.raw)
      this.invoice.file = file.raw
    },
    updateSideBar(val) {
      this.isAddNewInvoiceSidebarActive = val;
    },
    hide() {
      this.isAddNewInvoiceSidebarActive = false;
    },
    resetForm() {
      this.invoice = {
        id: 0,
        application_codes: [],
        payment_method_id: [],
        application_remark: "",
      };
    },
    payment(item) {
      console.log("payment", item);
      this.invoice.client_code = item.client.client_code;
      this.invoice.total_amount = item.total_amount;
      this.invoice.total_paid = item.total_paid;
      this.invoice.invoice_number = item.invoice_number;
      this.invoice.total_amount = item.total_amount;
      this.invoice.invoice_id = item.id;
      this.invoice.item = "The deposit payment of the invoice no. " + item.invoice_number + " of the total amount of HK$" + (item.total_amount - item.total_paid);
      this.invoice.payment_amount = item.total_amount - item.total_paid;
      this.invoice.payment_method_id = 1;
      this.invoice.payment_date = "";
      this.invoice.payment_remark = "";
      this.isAddNewInvoiceSidebarActive = true;
    },

    getDate(str) {
      return str.substring(0, 10);
    },
    getNumberFormat(amount) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HKD",
        minimumFractionDigits: 0,
      });
      return formatter.format(amount);
    },

    onSubmit() {
      this.$swal({
        title: "確認提交?",
        text: "",
        showCancelButton: true,
        confirmButtonText: "儲存",
        cancelButtonText: '取消',
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          let formData = new FormData()
          formData.append('file', this.invoice.file)
          formData.append('invoice_id', this.invoice.invoice_id)
          formData.append('payment_amount', this.invoice.payment_amount)
          formData.append('item', this.invoice.item)
          formData.append('payment_method_id', this.invoice.payment_method_id)
          formData.append('payment_date', this.invoice.payment_date)
          formData.append('payment_remark', this.invoice.payment_remark)
          formData.append('receipt_template_id', this.invoice.receipt_template_id)
          this.$store
            .dispatch("app-client/addInvoicePayment", formData)
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.loading = false;
              this.hide();
              this.$emit("refetch-data");
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  props: {
    invoiceData: {
      type: Array,
      required: true,
    },
    tableInvoiceColumns: {
      type: Array,
      required: true,
    },
    paymentOptions: {
      type: Array,
      required: true,
    },
    receiptTemplateOptions:{
      type: Array,
      required:true
    }
  },

  setup() {
    const APP_CLIENT_STORE_MODULE_NAME = "app-client";
    const tableLoading = ref(false)
    const showUpload = ref(true)
    const isSortDirDesc = ref(true);
    const isAddNewInvoiceSidebarActive = ref(false);
    const uploadUrl = ref(process.env.VUE_APP_SERVICE_URL + '/invoice');
    const headerObj = { 'Authorization': 'Bearer ' + localStorage.getItem('accessToken') }
    const fileList = ref([])
    const invoice = ref({
      id: 0,
      application_codes: [],
      payment_method_id: [],
      application_remark: "",
    });

    if (!store.hasModule(APP_CLIENT_STORE_MODULE_NAME))
      store.registerModule(APP_CLIENT_STORE_MODULE_NAME, clientStoreModule);

    const loading = ref(false);

    const blankInvoiceData = {
      id: 0,
      application_codes: [],
      application_remark: "",
    };
    const button_text = ref("Save");

    return {
      isSortDirDesc,
      isAddNewInvoiceSidebarActive,
      invoice,
      button_text,
      loading,
      showUpload,
      uploadUrl,
      headerObj,
      fileList,
      tableLoading
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.badge {
  display: block;
  margin: 5px;
  max-width: 200px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.badge {
  background-color: $primary;
  opacity: 0.8;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100%;
  height: 100%;
  line-height: 150px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
  border-radius: 0%;

}

.hideUpload>div {
  display: none;
}
</style>
