var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('project-list-add-new',{attrs:{"is-add-new-project-sidebar-active":_vm.isAddNewProjectSidebarActive,"id":_vm.id,"data":_vm.project,"projectstage-options":_vm.projectstageOptions,"application-options":_vm.applicationOptions,"document-type-options":_vm.documentTypeOptions,"contract-options":_vm.contractOptions},on:{"update:isAddNewProjectSidebarActive":function($event){_vm.isAddNewProjectSidebarActive=$event},"update:is-add-new-project-sidebar-active":function($event){_vm.isAddNewProjectSidebarActive=$event},"refetch-data":_vm.refetchData}}),_c('modal-stage',{attrs:{"project-data":_vm.project,"projectstage-options":_vm.projectstageOptions},on:{"refetch-data":_vm.refetchData}}),_c('modal-application',{attrs:{"project-data":_vm.project,"application-options":_vm.applicationOptions},on:{"refetch-data":_vm.refetchData}}),_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('b-table',{ref:"refProjectListTable",staticClass:"position-relative",attrs:{"items":_vm.projectData,"responsive":"","fields":_vm.tableProjectColumns,"primary-key":"id","show-empty":"","empty-text":"No matching records found"},scopedSlots:_vm._u([{key:"cell(application_codes)",fn:function(data){return _vm._l((data.item.application_codes),function(application_code){return _c('div',{key:application_code.code_name,staticClass:"text-capitalize",on:{"click":function($event){return _vm.openApplicationModal(data.item)}}},[_vm._v(" "+_vm._s(application_code.code_name)+" ")])})}},{key:"cell(client_id)",fn:function(data){return [_c('b-link',{attrs:{"to":{
            name: 'clients-detail',
            params: { id: data.item.client_id },
          }}},[_vm._v(_vm._s(data.item.client.client_code))])]}},{key:"cell(project_stage)",fn:function(data){return [(data.item.project_stage)?_c('b-badge',{staticClass:"text-capitalize",style:({
            background: data.item.project_stage.background_color,
            color: data.item.project_stage.font_color,
          }),on:{"click":function($event){return _vm.openStageModal(data.item)}}},[_vm._v(" "+_vm._s(data.item.project_stage.stage)+" ")]):_vm._e()]}},{key:"cell(project_stage_id)",fn:function(data){return [(data.item.project_stage)?_c('b-badge',{staticClass:"text-capitalize",style:({
            background: data.item.project_stage.background_color,
            color: data.item.project_stage.font_color,
          }),on:{"click":function($event){return _vm.openStageModal(data.item)}}},[_vm._v(" "+_vm._s(data.item.project_stage.stage)+" ")]):_vm._e()]}},{key:"cell(created_at)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.getDate(data.item.created_at))+" ")]}},{key:"cell(application_remark)",fn:function(data){return [_c('div',{domProps:{"innerHTML":_vm._s(data.item.application_remark)}})]}},{key:"cell(actions)",fn:function(data){return [_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","right":_vm.$store.state.appConfig.isRTL},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.handleEdit(data.item)}}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Edit")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }