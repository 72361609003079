<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <h4 class="mb-0">Basic Information</h4>
    </div>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
          <b-row>
            <!--Field: Company Name -->
            <b-col cols="12">
              <hr />
            </b-col>
            <b-col cols="12" md="12">
              <b-form-group
                class=""
                label="Group Code"
                label-for="group_code"
                label-cols-md="4"
              >
                <v-select
                  v-model="clientData.group_code_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="groupcodeOptions"
                  label="group_code"
                  :reduce="(option) => option.id"
                  :clearable="true"
                >
                  <template slot="option" slot-scope="option">
                    [{{ option.group_code }}] {{ option.name_of_group }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="12">
              <validation-provider
                #default="validationContext"
                name="Company Name"
                rules="required"
              >
                <b-form-group
                  class="required"
                  label="Company Name"
                  label-for="company_name"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="company_name"
                    :state="getValidationState(validationContext)"
                    v-model="clientData.company_name"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Group Code
                        <b-col cols="12" md="12">
                            <b-form-group label="Group Code" label-for="group-code" label-cols-md="4">
                                <v-select v-model="clientData.group_code_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="groupcodeData" label="group_code" :reduce="(option) => option.id" :clearable="false">
                                    <template slot="option" slot-scope="option">
                                        [{{option.group_code}}] {{option.name_of_group}}
                                    </template>
                            </v-select>
                        </b-form-group>
                    </b-col>-->
            <!-- Field: Company Name (EN) -->
            <b-col cols="12" md="12">
              <validation-provider
                #default="validationContext"
                name="Company Name (EN)"
                rules="required"
              >
                <b-form-group
                  class="required"
                  label="Company Name (EN)"
                  label-for="company_name_en"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="company_name_en"
                    :state="getValidationState(validationContext)"
                    v-model="clientData.company_name_en"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Field: Contact Name-->
            <b-col cols="12" md="12">
              <validation-provider
                #default="validationContext"
                name="Contact Name"
                rules="required"
              >
                <b-form-group
                  class="required"
                  label="Contact Name"
                  label-for="contact_name"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="contact_name"
                    :state="getValidationState(validationContext)"
                    v-model="clientData.contact_name"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Field: Contact Title-->
            <b-col cols="12" md="12">
              <validation-provider
                #default="validationContext"
                name="Contact Title"
                rules="required"
              >
                <b-form-group
                  class="required"
                  label="Contact Title"
                  label-for="contact_title"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="contact_title"
                    :state="getValidationState(validationContext)"
                    v-model="clientData.contact_title"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Field: Contact Phone -->
            <b-col cols="12" md="12">
              <validation-provider
                #default="validationContext"
                name="Contact Phone"
                rules="required"
              >
                <b-form-group
                  class="required"
                  label="Contact Phone"
                  label-for="contact_phone"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="contact_phone"
                    :state="getValidationState(validationContext)"
                    v-model="clientData.contact_phone"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Field: Contact Email -->
            <b-col cols="12" md="12">
              <b-form-group
                class=""
                label="Contact Email"
                label-for="contact_email"
                label-cols-md="4"
              >
                <b-form-input
                  id="contact_email"
                  v-model="clientData.contact_email"
                />
              </b-form-group>
            </b-col>
            <!-- -->
            <div class="d-flex">
              <h4 class="mb-0 ml-1">Company Information</h4>
            </div>
            <b-col cols="12">
              <hr />
            </b-col>
            <!-- Field: BR Number -->
            <b-col cols="12" md="12">
              <b-form-group
                class=""
                label="BR Number"
                label-for="br_no"
                label-cols-md="4"
              >
                <b-form-input id="br_no" v-model="clientData.br_no" />
              </b-form-group>
            </b-col>
            <!-- Field: BR Address -->
            <b-col cols="12" md="12">
              <b-form-group
                class=""
                label="BR Address"
                label-for="br_address"
                label-cols-md="4"
              >
                <b-form-input id="br_address" v-model="clientData.br_address" />
              </b-form-group>
            </b-col>
            <!-- Field: Number of Employees -->
            <b-col cols="12" md="12">
              <b-form-group
                class=""
                label="Number of Employees"
                label-for="no_of_employees"
                label-cols-md="4"
              >
                <b-form-input
                  id="no_of_employees"
                  placeholder="在港僱用人數"
                  v-model="clientData.no_of_employees"
                />
              </b-form-group>
            </b-col>
            <!-- Field: Industry -->
            <b-col cols="12" md="12">
              <b-form-group
                class=""
                label="Industry"
                label-for="customer_type"
                label-cols-md="4"
              >
                <b-form-input
                  id="customer_type"
                  placeholder="行業"
                  v-model="clientData.customer_type"
                />
              </b-form-group>
            </b-col>
            <!-- Field: Business Nature -->
            <b-col cols="12" md="12">
              <b-form-group
                class=""
                label="Business Nature"
                label-for="business_nature"
                label-cols-md="4"
              >
                <b-form-input
                  id="business_nature"
                  placeholder="業務性質(For 作文)"
                  v-model="clientData.business_nature"
                />
              </b-form-group>
            </b-col>
            <div class="d-flex">
              <h4 class="mb-0 ml-1">Application Information</h4>
            </div>
            <b-col cols="12">
              <hr />
            </b-col>
            <!-- Field: Application Email -->
            <b-col cols="12" md="12">
              <b-form-group
                class=""
                label="Application Email"
                label-for="application_email"
                label-cols-md="4"
              >
                <b-form-input
                  id="application_email"
                  placeholder="Application Email"
                  v-model="clientData.application_email"
                />
              </b-form-group>
            </b-col>
            <!-- Field: Application Email Password -->
            <b-col cols="12" md="12">
              <b-form-group
                class=""
                label="Application Email Password"
                label-for="application_email_password"
                label-cols-md="4"
              >
                <b-form-input
                  id="application_email_password"
                  placeholder="Application Email Password"
                  v-model="clientData.application_email_password"
                />
              </b-form-group>
            </b-col>
            <!-- Field: Auto Forward -->
            <b-col cols="12" md="12">
              <b-form-group
                class=""
                label="Auto Forward"
                label-for="auto_forward"
                label-cols-md="4"
              >
                <b-form-input
                  id="auto_forward"
                  placeholder="Auto Forward"
                  v-model="clientData.auto_forward"
                />
              </b-form-group>
            </b-col>
            <div class="d-flex">
              <h4 class="mb-0 ml-1">Sales</h4>
            </div>
            <b-col cols="12">
              <hr />
            </b-col>
            <b-col cols="12" md="12">
              <b-form-group
                label="Sales"
                label-for="sales_id"
                label-cols-md="4"
              >
                <v-select
                  v-model="clientData.sales_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="userOptions"
                  label="name"
                  :reduce="(option) => option.id"
                  :clearable="false"
                >
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="12">
              <b-form-group label="Remark" label-for="remark" label-cols-md="4">
                <quill-editor v-model="clientData.remark" />
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Form Actions -->

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ button_text }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
  BFormDatepicker,
  BInputGroupAppend,
  BInputGroup,
  BFormInvalidFeedback,
  BOverlay,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref, onMounted, watch } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import "animate.css";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";
import { max } from "@/libs/validations.js";
import { heightTransition } from "@core/mixins/ui/transition";
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormCheckbox,
    BFormDatepicker,
    BInputGroupAppend,
    BInputGroup,
    BFormInvalidFeedback,
    BOverlay,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  methods: {
    removeItem(index) {
      this.clientData.directors.splice(index, 1);
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    repeateAgain() {
      this.clientData.directors.push({
        id: this.clientData.directors.length,
      });
    },
    hide() {
      this.$router.replace("/clients/list");
    },
    setDemoData() {
      this.clientData = {
        id: "0",
        client_code: "2A600",
        client_name_cn: "FTW Limited",
        client_name_en: "FTW Limited",
        principal_activities: "Accounting",
        contact_person: "Nick",
        registered_office_address: "Kowloon",
        business_address: "Kowloon",
        telephone_no: "26612345",
        email: "demo@ftw.com",
      };
    },
    onSubmit() {
      /*
            this.clientData.service_code_id = []
            this.clientData.service_codes.forEach(item => {
                console.log(item)
                if (item.id) {
                    this.clientData.service_code_id.push(item.id)
                } else {
                    this.clientData.service_code_id.push(item)
                }
            })
            */
      this.$swal({
        title: "確認儲存?",
        showCancelButton: true,
        confirmButtonText: "儲存",
        cancelButtonText: '取消',
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("app-client/updateClient", this.clientData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              if (this.clientData.id == 0) {
                // Return to client list
                this.hide();
              }
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  props: {
    clientData: {
      type: Object,
      required: true,
    },
    userOptions: {
      type: Array,
      required: true,
    },
    groupcodeOptions: {
      type: Array,
      required: true,
    },
  },

  setup(props, { emit }) {
    const loading = ref(false);

    const button_text = ref("Add");

    const resetclientData = () => {
      props.clientData = JSON.parse(JSON.stringify({}));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetclientData);

    const checkEdit = () => {
      if (props.clientData.id > 0) {
        button_text.value = "Edit";
      } else {
        button_text.value = "Add";
      }
    };

    checkEdit();

    watch(
      () => props.clientData,
      (selection, prevSelection) => {
        checkEdit();
      }
    );

    return {
      button_text,
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.required > label::after {
  content: " **";
  color: red;
}

.vs__selected path {
  fill: white;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;
    .dark-layout & {
      background-color: unset;
    }
  }
}
.add-new-group-header {
  color: #28c76f;
  margin-left: 15px;
}

.ql-editor {
  height: 300px;
}
</style>
