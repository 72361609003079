<template>
    <b-modal :id="`modal-document${id}`" cancel-variant="outline-secondary" scrollable size="lg" title="查閱上傳文件" centered>
        <modal-image-viewer :id="id" :image-list="previewImage" :selected_file="selected_file"></modal-image-viewer>
        <div style="width: 700px; max-width: 100%;" class="d-flex align-items-center mb-2">
            <b-form-file v-model="upload_document" placeholder="選擇檔案及按上傳收據"></b-form-file>
            <div class="d-flex ml-2 align-items-end">
                <b-button class="mr-2 text-nowrap" variant="primary" @click="uploadDocument">
                    上傳收據
                </b-button>
            </div>
        </div>
        <hr />
        <div class="bold text-16 mb-1">文件列表</div>
        <div v-if="payment_documents && payment_documents.length > 0" class="photo-album">
            <b-row class="width:100%">
                <b-col md="12" class="mb-1" :key="document.name + index" v-for="(document, index) of payment_documents">
                    <b-row class="w-100">
                        <b-col md="8">

                            <a class="d-flex aling-items-center" @click="beforeSubmitPreview(index)">
                                <span class="mr-1 text-nowrap">{{ document.name }}</span>
                                <template v-if="previewImage && previewImage.length > 0">
                                    <img v-if="document.fileType == 'application/pdf'" class="preview_thumbnail"
                                        :src="previewImage[index].image_src" />
                                    <img v-else class="preview_thumbnail" :src="previewImage[index].src" />
                                </template>
                            </a>

                        </b-col>
                        <b-col md="4">
                            <feather-icon @click="downloadDocument(document)" class="cursor-pointer text-success" icon="DownloadIcon"
                                size="21" />
                            <span class="mx-1">|</span>
                            <feather-icon @click="deleteDocument(document.file)" class="cursor-pointer text-danger"
                                icon="Trash2Icon" size="21" />
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </div>
    </b-modal>
</template>

<script>
import {
    BModal,
    BFormFile,
    BButton,
    BRow,
    BCol,
} from "bootstrap-vue";
import ModalImageViewer from "@/layouts/components/file/ModalImageViewer.vue";
import invoiceStoreModule from "@/views/invoice/invoiceStoreModule";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import axiosIns from '@/libs/axios'

export default {
    data() {
        return {
            upload_document: null,
            selected_file: null,
        };
    },
    components: {
        BModal,
        BFormFile,
        ModalImageViewer,
        BButton,
        BRow,
        BCol,
    },
    props: {
        invoice_id: {
            type: Number
        },
        id: {},
    },
    methods: {
        uploadDocument() {
            this.$swal({
                title: "上傳檔案?",
                showCancelButton: true,
                confirmButtonText: "上傳",
                cancelButtonText: "取消",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    this.loading = true;

                    let formData = new FormData();
                    formData.append("file", this.upload_document);
                    formData.append("invoice_id", this.invoice_id);
                    store
                        .dispatch("invoice/uploadPaymentDocument", formData)
                        .then((response) => {
                            this.loading = false;
                            this.$swal({
                                text: response.data.message,
                                icon: "success",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                showClass: {
                                    popup: "animate__animated animate__bounceIn",
                                },
                                buttonsStyling: false,
                            });
                            this.upload_document = null;
                            this.refetchPaymentDocument();
                        })
                        .catch((error) => {
                            this.loading = false;
                            this.$swal({
                                text: JSON.stringify(error.response.data.message),
                                icon: "error",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                showClass: {
                                    popup: "animate__animated animate__bounceIn",
                                },
                                buttonsStyling: false,
                            });
                        });
                }
            });
        },
        downloadDocument(doc) {
            var lastDotIndex = doc.name.lastIndexOf(".");
            var replacedStr =
                doc.name.substring(0, lastDotIndex).replace(/\./g, "_") +
                doc.name.substring(lastDotIndex);

            store
                .dispatch("invoice/downloadPaymentDocument", doc.file)
                .then((response) => {
                    this.loading = false;
                    let url = URL.createObjectURL(response.data);
                    let pom = document.createElement("a");
                    pom.setAttribute("href", url);

                    pom.setAttribute("download", replacedStr);
                    pom.click(); // this will download the file
                    this.$swal({
                        text: "成功下載",
                        icon: "success",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        showClass: {
                            popup: "animate__animated animate__bounceIn",
                        },
                        buttonsStyling: false,
                    });
                })
                .catch((error) => {
                    console.log("error", error);
                    this.loading = false;
                    this.$swal({
                        text: JSON.stringify(error),
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        showClass: {
                            popup: "animate__animated animate__bounceIn",
                        },
                        buttonsStyling: false,
                    });
                });
        },
        deleteDocument(file) {
            this.$swal({
                title: "確認刪除?",
                text: "Your actions are final and you will not be able to retrieve this record.",
                showCancelButton: true,
                confirmButtonText: "刪除",
                cancelButtonText: '取消',
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    store
                        .dispatch("invoice/deletePaymentDocument", file)
                        .then((response) => {
                            this.loading = false;
                            this.$swal({
                                text: response.data.message,
                                icon: "success",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                showClass: {
                                    popup: "animate__animated animate__bounceIn",
                                },
                                buttonsStyling: false,
                            });
                            this.refetchPaymentDocument();
                        })
                        .catch((error) => {
                            this.loading = false;
                            this.$swal({
                                text: JSON.stringify(error),
                                icon: "error",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                showClass: {
                                    popup: "animate__animated animate__bounceIn",
                                },
                                buttonsStyling: false,
                            });
                        });
                }
            });
        },
        beforeSubmitPreview(index) {
            
            this.selected_file = index;
            this.$bvModal.show(`modal-image-viewer${this.id}`);
        },
    },
    watch: {
        invoice_id() {
            this.refetchPaymentDocument();
        }
    },
    setup(props) {
        const INVOICE_STORE_MODULE_NAME = "invoice";
        const loading = ref(false);
        const payment_documents = ref([]);
        const previewImage = ref(null);

        if (!store.hasModule(INVOICE_STORE_MODULE_NAME))
            store.registerModule(INVOICE_STORE_MODULE_NAME, invoiceStoreModule);

        onUnmounted(() => {
            if (store.hasModule(INVOICE_STORE_MODULE_NAME))
                store.unregisterModule(INVOICE_STORE_MODULE_NAME);
        });

        const refetchPaymentDocument = () => {
            loading.value = true;
            store
                .dispatch("invoice/fetchPaymentDocument", {
                    invoice_id: props.invoice_id
                })
                .then((response) => {
                    loading.value = false;
                    payment_documents.value = response.data.file_lists;

                    previewImage.value = []
                    response.data.file_lists.forEach(document => {
                        preview_thumbnail(document.path, document.fileType)
                    })
                })
                .catch((error) => {
                    loading.value = false;
                    console.log("Error fetching the options.", error);
                });
        };

        const preview_thumbnail = (url, type) => {
            axiosIns.get(url, { responseType: "blob" }).then((response) => {
                if (type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
                    previewImage.value.push({
                        src: require('@/assets/images/icons/document/word.png'),
                        type: type,
                    })

                } else if (type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || type == "application/vnd.ms-excel") {
                    previewImage.value.push({
                        src: require('@/assets/images/icons/document/excel.png'),
                        type: type,
                    })

                } else if (type == "application/pdf") {
                    let file_path = URL.createObjectURL(response.data)

                    previewImage.value.push({
                        image_src: require('@/assets/images/icons/document/pdf.png'),
                        src: file_path,
                        type: type,
                    })

                } else if (
                    type == "image/jpeg" ||
                    type == "image/jpg" ||
                    type == "image/png" ||
                    type == "image/gif" ||
                    type == "image/webp" ||
                    type == "image/svg+xml") {

                    let file_path = URL.createObjectURL(response.data)
                    previewImage.value.push({
                        src: file_path,
                        type: type,
                    })
                } else if (
                    type != "image/jpeg" &&
                    type != "image/jpg" &&
                    type != "image/png" &&
                    type != "image/gif" &&
                    type != "image/webp" &&
                    type != "image/svg+xml") {

                    previewImage.value.push({
                        src: require('@/assets/images/icons/document/others.jpeg'),
                        type: type,
                    })
                }
            });
        }

        /*   onMounted(() => {
              refetchPaymentDocument();
          }); */

        return {
            payment_documents,
            refetchPaymentDocument,
            previewImage
        }



    }
}

</script>

<style>
.preview_thumbnail{
    width: 50px;
  }
</style>