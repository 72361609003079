import axiosIns from "@/libs/axios";

export default {
  namespaced: true,

  actions: {
    fetchPayments(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/invoice_payment/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deletePayment(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/invoice_payment", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    audit(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/invoice_payment/audit", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updatePayment(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .patch("/invoice_payment", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    restorePayment(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .patch(`/invoice_payment/${id}/restore`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchReceiptTemplates(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/receipt_template/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchReceiptTemplate(context, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/receipt_template", {
            params: queryParams,
            responseType: "blob", // Ensure the response type is set to 'blob' to handle binary data
          })
          .then((response) => {
            // Check if the response headers include 'content-disposition'
            const contentDisposition = response.headers["content-disposition"];
            let filename = "template.docx"; // A default filename if none is provided
            if (contentDisposition) {
              // Extract filename from content-disposition header
              const filenameMatch =
                contentDisposition.match(/filename="?(.+)"?/);
              if (filenameMatch && filenameMatch.length > 1) {
                filename = filenameMatch[1];
              }
            }

            resolve({
              blob: response.data,
              filename: filename,
            });
          })
          .catch((error) => {
            // Handle errors such as network issues or server errors
            console.error("Error fetching the receipt template:", error);
            reject(error);
          });
      });
    },
    editReceiptTemplate(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/receipt_template", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteReceiptTemplate(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete("/receipt_template", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    downloadReceipt(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns.get('/invoice_payment/export', { params: queryParams , responseType: "blob" })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    exportPayments(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/reports/payments/export", {
            params: queryParams,
            responseType: "blob",
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

  },
};
