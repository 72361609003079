var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"client-header",attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"text-center"},[_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"value":_vm.clientData.id,"filter":_vm.clientSearch,"options":_vm.clientOptions,"label":"client_code","reduce":function (val) { return val.id; }},on:{"input":function (val) { return _vm.changeClient(val); }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var client_code = ref.client_code;
var company_name_cn = ref.company_name_cn;
var company_name_en = ref.company_name_en;
return [_vm._v(" ["+_vm._s(client_code)+"] "+_vm._s(company_name_cn)+" "+_vm._s(company_name_en)+" ")]}},{key:"selected-option",fn:function(ref){
var client_code = ref.client_code;
var company_name_cn = ref.company_name_cn;
var company_name_en = ref.company_name_en;
return [_c('h3',{staticClass:"text-primary"},[_vm._v(" ["+_vm._s(client_code)+"] "+_vm._s(company_name_cn)+" "+_vm._s(company_name_en))])]}}])}),_c('h4',{staticClass:" mt-2"}),_c('b-col',{staticClass:"mt-5",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-lg",modifiers:{"modal-lg":true}}],attrs:{"variant":"primary"}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v("項目")])],1),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-contract",modifiers:{"modal-contract":true}}],attrs:{"variant":"primary"}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v("合約")])],1),_c('b-button',{attrs:{"variant":"primary","to":{ name: 'invoices-add', params: { client_id: _vm.clientData.id } }}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v("發票")])],1),_c('b-button',{attrs:{"variant":"primary","to":{ name: 'clients-list' }}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("客戶列表")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }