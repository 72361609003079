import axiosIns from '@/libs/axios'

export default {
    namespaced: true,

    actions: {
        fetchPaymentAccounts(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns.get('/payment_account/list', {params: queryParams}).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        fetchPaymentAccount(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axiosIns.get(`/payment_account/${id}`).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        addPaymentAccount(ctx, data) {
            return new Promise((resolve, reject) => {
                axiosIns.post('/payment_account', data).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        fetchInvoices(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns.get('/invoice/list', {params: queryParams}).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        fetchPaymentMethods(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns.get('/invoice/getPaymentMethods', {params: queryParams}).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        fetchInvoice(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axiosIns.get(`/invoice/${id}`).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        deleteInvoice(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns.delete('/invoice', {params:queryParams})
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        fetchInvoiceInformation(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns.get('/invoice/information', {params: queryParams}).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        addInvoice(ctx, data) {
            return new Promise((resolve, reject) => {
                axiosIns.post('/invoice', data).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        addInvoicePayment(ctx, data) {
            return new Promise((resolve, reject) => {
                axiosIns.post('/invoice/payment', data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        fetchUsers(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns.get('/user/list', {params: queryParams}).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        fetchClients(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns.get('/client/list', {params: queryParams}).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        fetchClient(ctx, id) {
            return new Promise((resolve, reject) => {
                axiosIns.get(`/client/${id}`).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        fetchInvoiceTemplates(ctx, queryParams){
            return new Promise((resolve, reject) => {
                axiosIns.get('/invoice_template/list', {params:queryParams})
                .then(response=>resolve(response))
                .catch(error=>reject(error))
            })
          },
          fetchInvoiceTemplate(ctx, queryParams){
            return new Promise((resolve, reject) => {
                axiosIns.get('/invoice_template', { params: queryParams , responseType: "blob" })
                .then(response=>resolve(response))
                .catch(error=>reject(error))
            })
          },
          editInvoiceTemplate(ctx, data) {
            return new Promise((resolve, reject) => {
              axiosIns.post('/invoice_template', data,)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
          },
          deleteInvoiceTemplate(ctx, queryParams) {
            return new Promise((resolve, reject) => {
              axiosIns
                .delete("/invoice_template", { params: queryParams })
                .then((response) => resolve(response))
                .catch((error) => reject(error));
            });
          },
          downloadInvoice(ctx, queryParams) {
            return new Promise((resolve, reject) => {
              axiosIns.get('/invoice/export', { params: queryParams , responseType: "blob" })
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
          },
          fetchPaymentDocument(ctx, queryParams) {
            return new Promise((resolve, reject) => {
              axiosIns.get('/invoice/document', {params:queryParams})
              .then(response => resolve(response))
              .catch(error => reject(error))
            })
          },
          uploadPaymentDocument(ctx, data) {
            return new Promise((resolve, reject) => {
              axiosIns.post('/invoice/document', data)
              .then(response => resolve(response))
              .catch(error => reject(error))
            })
          },
          downloadPaymentDocument(ctx, data) {
            return new Promise((resolve, reject) => {
              axiosIns.get(`/invoice/document/${data}`,{ responseType: "blob" })
              .then(response => resolve(response))
              .catch(error => reject(error))
            })
          },
          deletePaymentDocument(ctx, data) {
            return new Promise((resolve, reject) => {
              axiosIns.delete(`/invoice/document/${data}`)
              .then(response => resolve(response))
              .catch(error => reject(error))
            })
          },
          restoreInvoice(ctx, id) {
            return new Promise((resolve, reject) => {
              axiosIns
                .patch(`/invoice/${id}/restore`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
            });
          },
          exportInvoices(ctx, queryParams) {
            return new Promise((resolve, reject) => {
              axiosIns
                .get("/reports/invoices/export", {
                  params: queryParams,
                  responseType: "blob",
                })
                .then((response) => resolve(response))
                .catch((error) => reject(error));
            });
          },

    }
}
