<template>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-modal
        id="modal-application"
        ok-title="修改"
        cancel-title="取消"
        cancel-variant="outline-secondary"
        size="sm"
        centered
        title="修改服務"
        no-close-on-backdrop
        @ok.prevent="handleSubmit(onSubmit)"
      >
        <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
            <b-form-group
              class=""
              label="項目服務"
              label-for="application_codes"
            >
              <v-select
                multiple
                v-model="projectData.application_codes"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="applicationOptions"
                label="code_name"
                :reduce="(option) => option.id"
              >
              </v-select>
            </b-form-group>
          </b-form>
        </b-overlay>
      </b-modal>
    </validation-observer>
  </template>
  
  <script>
  import {
    BModal,
    VBModal,
    BCardText,
    BOverlay,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BCardBody,
    BCard,
  } from "bootstrap-vue";
  
  import Ripple from "vue-ripple-directive";
  import { ref, onUnmounted } from "@vue/composition-api";
  import store from "@/store";
  import vSelect from "vue-select";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import formValidation from "@core/comp-functions/forms/form-validation";

  export default {
    data() {
      return {};
    },
    components: {
      BModal,
      VBModal,
      BCardText,
      BOverlay,
      BForm,
      BFormGroup,
      BRow,
      BCol,
      BCardBody,
      BCard,
      vSelect,
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      "b-modal": VBModal,
      Ripple,
    },
    props: {
      projectData: {
        type: Object,
        required: true,
      },
      applicationOptions: {
        type: Array,
        required: true,
      },
    },
    methods: {
      onSubmit() {
        this.$swal({
          title: "確認儲存?",
          showCancelButton: true,
          confirmButtonText: "儲存",
          cancelButtonText: '取消',
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.loading = true;
            var application_code_id = []
            this.projectData.application_codes.forEach(item => {
                if(typeof item == 'object')
            {
                application_code_id.push(item.id)
            }else{
                application_code_id.push(item)
            }
            })
            store
              .dispatch("project/updateApplication", {id: this.projectData.id,application_code_id})
              .then((response) => {
                this.loading = false;
                this.$swal({
                  text: response.data.message,
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
                this.$emit("refetch-data");
                this.$emit("refetch-statistic");
                this.hide();
  
              })
              .catch((error) => {
                this.loading = false;
                this.$swal({
                  text: JSON.stringify(error.response.data.message),
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
              });
          }
        });
      },
      hide() {
        this.$bvModal.hide('modal-application')
      },
    },
    setup(props, { emit }) {
 
      const loading = ref(false);
      const resetprojectData = () => {
        props.projectData = JSON.parse(JSON.stringify({}));
      };
  
      const itemFormBlankItem = {
        id: 0,
        application_codes: null,
      };
  
      const { refFormObserver, getValidationState, resetForm } =
        formValidation(resetprojectData);
  
      return {
        loading,
        refFormObserver,
        getValidationState,
        resetForm,
        itemFormBlankItem,
      };
    },
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
  