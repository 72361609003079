var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('modal-document',{attrs:{"id":_vm.modalId,"invoice_id":_vm.invoice_id}}),_c('modal-pdf',{attrs:{"id":"-client-payment-list","pdfUrl":_vm.pdfUrl}}),(_vm.ability.can('update', 'payments'))?_c('modal-payment',{attrs:{"is-add-new-payment-sidebar-active":_vm.isAddNewPaymentSidebarActive,"id":_vm.id,"data":_vm.editData,"payment-options":_vm.paymentOptions,"receipt-template-options":_vm.receiptTemplateOptions},on:{"update:isAddNewPaymentSidebarActive":function($event){_vm.isAddNewPaymentSidebarActive=$event},"update:is-add-new-payment-sidebar-active":function($event){_vm.isAddNewPaymentSidebarActive=$event},"refetch-data":_vm.refetchData}}):_vm._e(),_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('b-table',{ref:"refPaymentListTable",staticClass:"position-relative",attrs:{"items":_vm.paymentData,"responsive":"","fields":_vm.tablePaymentColumns,"primary-key":"id","show-empty":"","empty-text":"No matching records found"},scopedSlots:_vm._u([{key:"cell(client)",fn:function(data){return [_c('b-link',{attrs:{"to":{
            name: 'clients-detail',
            params: { id: data.item.invoice.client.id },
          }}},[_vm._v(_vm._s(data.item.invoice.client.client_code))])]}},{key:"cell(document)",fn:function(data){return [_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(40, 199, 111, 0.15)'),expression:"'rgba(40, 199, 111, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon",attrs:{"variant":"flat-primary","target":"_blank"},on:{"click":function($event){return _vm.downloadDocument(data.item.invoice_id)}}},[_c('feather-icon',{attrs:{"icon":"DownloadCloudIcon"}})],1)]}},{key:"cell(receipt)",fn:function(data){return [(data.item.is_audit)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(40, 199, 111, 0.15)'),expression:"'rgba(40, 199, 111, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon",attrs:{"variant":"flat-primary","target":"_blank"},on:{"click":function($event){return _vm.downloadReceipt(data.item.id)}}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}}),_vm._v(" "+_vm._s(data.item.receipt_id)+" ")],1):_vm._e()]}},{key:"cell(invoice)",fn:function(data){return [_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(40, 199, 111, 0.15)'),expression:"'rgba(40, 199, 111, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon",attrs:{"variant":"flat-success","to":{
            name: 'invoices-preview',
            params: { id: data.item.invoice_id },
          }}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}})],1)]}},{key:"cell(payment_remark)",fn:function(data){return [_c('div',{domProps:{"innerHTML":_vm._s(data.item.payment_remark)}})]}},{key:"cell(payment_amount)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.getNumberFormat(data.item.payment_amount))+" ")]}},{key:"cell(is_audit)",fn:function(data){return [(data.item.is_audit)?_c('feather-icon',{staticClass:"mr-50 text-success",attrs:{"icon":"CheckIcon","size":"18"}}):_vm._e()]}},{key:"cell(actions)",fn:function(data){return [_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","right":_vm.$store.state.appConfig.isRTL},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.handleEdit(data.item)}}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("修改")])],1),(
              _vm.ability.can('delete', 'payments') &&
              data.item.deleted_at == null
            )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.confirmDelete(data.item.id)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("刪除")])],1):_vm._e()],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }