<template>
  <div>
    <b-sidebar
      id="add-new-quotation-sidebar"
      :visible="isAddNewQuotationSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="resetForm"
      @change="(val) => updateSideBar(val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">
            Payment Detail - {{ quotation.quotation_number }} ({{
              quotation.client_code
            }})
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- Body -->
        <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="onSubmit"
            @reset.prevent="resetForm"
          >
            <b-form-group class="" label="應付金額" label-for="total_amount">
              <b-form-input
                id="total_amount"
                v-model="quotation.total_amount"
                disabled
              />
            </b-form-group>
            <b-form-group
              class="required"
              label="支付金額"
              label-for="payment_amount"
            >
              <b-form-input
                id="payment_amount"
                v-model="quotation.payment_amount"
              />
            </b-form-group>
            <b-form-group class="required" label="支付ITEM" label-for="item">
              <b-form-input id="item" v-model="quotation.item" />
            </b-form-group>
            <b-form-group
              class="required"
              label="支付方法"
              label-for="payment_method_id"
            >
              <v-select
                v-model="quotation.payment_method_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="paymentOptions"
                label="method_name"
                :reduce="(option) => option.id"
                :clearable="false"
              >
              </v-select>
            </b-form-group>
            <!-- Description -->
            <b-form-group
              class="payment_date required"
              label="支付日期"
              label-for="payment_date"
            >
              <b-form-datepicker
                id="payment_date"
                placeholder="Choose a date"
                local="en"
                v-model="quotation.payment_date"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              />
            </b-form-group>
            <b-form-group label="收據">
              <el-upload
                class="avatar-uploader"
                list-type="picture-card"
                ref="upload"
                :action="uploadUrl"
                :headers="headerObj"
                name="image_file"
                :file-list="fileList"
                multiple
                :limit="1"
                :data="quotation"
                :on-change="onChange"
                :on-remove="toggleUpload"
                :before-remove="beforeRemove"
                :auto-upload="false"
                :class="{ hideUpload: !showUpload }"
              >
                <img
                  v-if="quotation.image_path && false"
                  :src="getImgPath(quotation.image_path)"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </b-form-group>
            <b-form-group label="Payment Remark" label-for="remark">
              <quill-editor v-model="quotation.payment_remark" />
            </b-form-group>

            <!-- Form Actions -->

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ button_text }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </b-overlay>
      </template>
    </b-sidebar>
    <b-card no-body class="mb-0">
      <b-table
        ref="refQuotationListTable"
        class="position-relative"
        :items="quotationData"
        responsive
        :fields="tableQuotationColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
        <!-- Column: Application Items -->
        <template #cell(application_codes)="data">
          <b-badge
            v-for="application_code in data.item.application_codes"
            :key="application_code.code_name"
            class="text-capitalize"
          >
            {{ application_code.code_name }}
          </b-badge>
        </template>

        <!-- Column: Quotation Stage -->
        <template #cell(quotation_stage_id)="data">
          <b-badge
            v-if="data.item.quotation_stage"
            class="text-capitalize"
            :style="{
              background: data.item.quotation_stage.background_color,
              color: data.item.quotation_stage.font_color,
            }"
          >
            {{ data.item.quotation_stage.stage }}
          </b-badge>
        </template>

        <template #cell(project)="data">
          <span v-if="data.item.project">{{
            data.item.project.project_id
          }}</span>
        </template>

        <!-- Column: Total Amount -->
        <template #cell(total_amount)="data">
          {{ getNumberFormat(data.item.total_amount) }}
        </template>

        <!-- Column: Total Paid -->
        <template #cell(total_paid)="data">
          {{ getNumberFormat(data.item.total_paid) }}
        </template>

        <!-- Column: Created at -->
        <template #cell(created_at)="data">
          {{ getDate(data.item.created_at) }}
        </template>

        <!-- Column: Export -->
        <template #cell(export)="data">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            class="btn-icon"
            :to="{ name: 'quotations-preview', params: { id: data.item.id } }"
          >
            <feather-icon icon="FileTextIcon" />
          </b-button>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="payment(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Pay</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSidebar,
  BOverlay,
  BForm,
  BFormGroup,
  BFormDatepicker,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import axiosIns from "@/libs/axios";
import quotationStoreModule from "@/views/quotation/quotationStoreModule";
import Ripple from "vue-ripple-directive";

import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSidebar,
    BForm,
    BFormGroup,
    BOverlay,
    vSelect,
    quillEditor,
    BFormDatepicker,
  },
  methods: {
    toggleUpload() {
      this.showUpload = !this.showUpload;
    },
    beforeRemove(file) {
      if (
        file.url ==
        process.env.VUE_APP_IMAGE_URL + this.quotation.image_path
      ) {
        this.removeExistingPic = true;
        this.quotation.image_path = null;
      }
    },
    onChange(file, fileLists) {
      this.showUpload = !this.showUpload;
      console.log(file.raw);
      this.quotation.file = file.raw;
    },
    updateSideBar(val) {
      this.isAddNewQuotationSidebarActive = val;
    },
    hide() {
      this.isAddNewQuotationSidebarActive = false;
    },
    resetForm() {
      this.quotation = {
        id: 0,
        application_codes: [],
        payment_method_id: [],
        application_remark: "",
      };
    },
    payment(item) {
      console.log("payment", item);
      this.quotation.client_code = item.client.client_code;
      this.quotation.total_amount = item.total_amount;
      this.quotation.total_paid = item.total_paid;
      this.quotation.quotation_number = item.quotation_number;
      this.quotation.total_amount = item.total_amount;
      this.quotation.quotation_id = item.id;
      this.quotation.item =
        "The deposit payment of the quotation no. " +
        item.quotation_number +
        " of the total amount of HK$" +
        (item.total_amount - item.total_paid);
      this.quotation.payment_amount = item.total_amount - item.total_paid;
      this.quotation.payment_method_id = 1;
      this.quotation.payment_date = "";
      this.quotation.payment_remark = "";
      this.isAddNewQuotationSidebarActive = true;
    },

    getDate(str) {
      return str.substring(0, 10);
    },
    getNumberFormat(amount) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HKD",
        minimumFractionDigits: 0,
      });
      return formatter.format(amount);
    },

    onSubmit() {
      this.$swal({
        title: "確認儲存?",
        text: "",
        showCancelButton: true,
        confirmButtonText: "儲存",
        cancelButtonText: '取消',
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          let formData = new FormData();
          formData.append("file", this.quotation.file);
          formData.append("quotation_id", this.quotation.quotation_id);
          formData.append("payment_amount", this.quotation.payment_amount);
          formData.append("item", this.quotation.item);
          formData.append(
            "payment_method_id",
            this.quotation.payment_method_id
          );
          formData.append("payment_date", this.quotation.payment_date);
          formData.append("payment_remark", this.quotation.payment_remark);
          this.$store
            .dispatch("app-client/addQuotationPayment", formData)
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.loading = false;
              this.hide();
              this.$emit("refetch-data");
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  props: {
    quotationData: {
      type: Array,
      required: true,
    },
    tableQuotationColumns: {
      type: Array,
      required: true,
    },
    paymentOptions: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const showUpload = ref(true);
    const isSortDirDesc = ref(true);
    const isAddNewQuotationSidebarActive = ref(false);
    const uploadUrl = ref(process.env.VUE_APP_SERVICE_URL + "/quotation");
    const headerObj = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };
    const fileList = ref([]);
    const quotation = ref({
      id: 0,
      application_codes: [],
      payment_method_id: [],
      application_remark: "",
    });

    const loading = ref(false);

    const blankQuotationData = {
      id: 0,
      application_codes: [],
      application_remark: "",
    };
    const button_text = ref("Save");

    return {
      isSortDirDesc,
      isAddNewQuotationSidebarActive,
      quotation,
      button_text,
      loading,
      showUpload,
      uploadUrl,
      headerObj,
      fileList,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.badge {
  display: block;
  margin: 5px;
  max-width: 200px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.badge {
  background-color: $primary;
  opacity: 0.8;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100%;
  height: 100%;
  line-height: 150px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
  border-radius: 0%;
}
.hideUpload > div {
  display: none;
}
</style>
