<template>
    <b-modal id="modal-payment" v-model="isModalActive" size="lg" :title="`${paymentData.id ? '修改' : '新增'}付款資料`"
        centered @change="(val) => $emit('update:is-add-new-payment-sidebar-active', val)" @hide="hide" @hidden="hide"
        :ok-title="`${paymentData.id ? '修改' : '新增'}付款資料`" cancel-title="取消" @ok.prevent="handleOk">

        <!-- Body -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
            <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
                <!-- Form -->
                <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                    <!-- 支款日期-->
                    <b-form-group class="" label="應付金額" label-for="total_amount" v-if="false">
                        <b-form-input id="total_amount" v-model="paymentData.total_amount" disabled />
                    </b-form-group>
                    <validation-provider #default="validationContext" name="payment_date" rules="required">
                        <b-form-group class="required" label="支付金額" label-for="payment_amount">
                            <b-form-input id="payment_amount" v-model="paymentData.payment_amount"
                                :state="getValidationState(validationContext)" />
                        </b-form-group>
                        <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                    </validation-provider>
                    <validation-provider #default="validationContext" name="支付方法" rules="required">
                        <b-form-group class="required" label="支付方法" label-for="payment_method_id">
                            <v-select v-model="paymentData.payment_method_id"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="paymentOptions"
                                label="method_name" :reduce="(option) => option.id" :clearable="false">
                            </v-select>
                        </b-form-group>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                    </validation-provider>

                    <validation-provider #default="validationContext" name="收據模版" rules="required">
                        <b-form-group class="required" label="收據模版" label-for="receipt_template_id">
                            <v-select v-model="paymentData.receipt_template_id"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="receiptTemplateOptions"
                                label="name" :reduce="(option) => option.id" :clearable="false">
                            </v-select>
                        </b-form-group>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                    </validation-provider>

                    <validation-provider #default="validationContext" name="payment_date" rules="required">
                        <b-form-group class="payment_date required" label="支款日期" label-for="payment_date">
                            <b-form-datepicker id="payment_date" :state="getValidationState(validationContext)"
                                placeholder="Choose a date" local="en" v-model="paymentData.payment_date"
                                :date-format-options="{
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                }" />
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <!-- 內部備註 -->
                    <b-form-group class="payment_remark required" label="內部備註" label-for="payment_remark">
                        <b-form-textarea v-model="paymentData.payment_remark" placeholder="內部備註" rows="3" />
                    </b-form-group>
                </b-form>
            </b-overlay>
        </validation-observer>
    </b-modal>
</template>


<script>
import {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    BFormCheckbox,
    BOverlay,
    BRow,
    BCol
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, watch, onUnmounted } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import { max } from "@/libs/validations.js";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import paymentStoreModule from "../paymentStoreModule";
import invoiceStoreModule from "@/views/invoice/invoiceStoreModule"


export default {
    directives: {
        Ripple,
    },
    components: {
        BModal,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        BFormTextarea,
        BFormCheckbox,
        vSelect,
        BOverlay,
        BFormDatepicker,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
        BRow,
        BCol
    },
    directives: {
        Ripple,
    },
    model: {
        prop: "isAddNewPaymentSidebarActive",
        event: "update:is-add-new-payment-sidebar-active",
    },
    props: {
        isAddNewPaymentSidebarActive: {
            type: Boolean,
            required: true,
        },
        paymentOptions: {
            type: Array,
            required: true,
        },
        receiptTemplateOptions: {
            type: Array,
            required: true
        },
        id: {
            type: Number,
            required: true,
        },
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            required,
            alphaNum,
            email,
            max,
        };
    },
    methods: {
        handleOk() {
            this.$refs.refFormObserver.validate().then((valid) => {
                if (valid) {
                    this.onSubmit();
                }
            });
        },
        hide() {
            this.$emit('update:is-add-new-payment-sidebar-active', false)
        }
    },
    setup(props, { emit }) {
        const PAYMENT_STORE_MODULE_NAME = "payment";
        const INVOICE_STORE_MODULE_NAME = "invoice";


        if (!store.hasModule(PAYMENT_STORE_MODULE_NAME))
            store.registerModule(PAYMENT_STORE_MODULE_NAME, paymentStoreModule);
        if (!store.hasModule(INVOICE_STORE_MODULE_NAME))
            store.registerModule(INVOICE_STORE_MODULE_NAME, invoiceStoreModule);

        onUnmounted(() => {
            if (store.hasModule(PAYMENT_STORE_MODULE_NAME))
                store.unregisterModule(PAYMENT_STORE_MODULE_NAME);
            if (store.hasModule(INVOICE_STORE_MODULE_NAME))
                store.unregisterModule(INVOICE_STORE_MODULE_NAME);
        });

        const isModalActive = ref(props.isAddNewPaymentSidebarActive);
        watch(() => props.isAddNewPaymentSidebarActive, (newVal) => {
            isModalActive.value = newVal;
        });

        const loading = ref(false);

        const blankPaymentData = {
            id: 0,
            total_amount: '',
            payment_amount: "",
            payment_method_id: null,
            payment_date: '',
            image_path: ''
        };
        const button_text = ref("提交");

        const paymentData = ref(JSON.parse(JSON.stringify(blankPaymentData)));

        const resetpaymentData = () => {
            paymentData.value = JSON.parse(JSON.stringify(blankPaymentData));
        };

        const toast = useToast();

        watch(
            () => [props.id, props.isAddNewPaymentSidebarActive],
            () => {
                if (props.id > 0 && props.isAddNewPaymentSidebarActive) {
                    paymentData.value = { ...props.data }
                    if (paymentData.value.id > 0) {
                        button_text.value = "提交";
                    } else {
                        button_text.value = "提交";
                    }
                } else {
                    button_text.value = "提交";
                }
            }
        );

        const onSubmit = () => {
            loading.value = true;
            store
                .dispatch("payment/updatePayment", paymentData.value)
                .then((response) => {
                    loading.value = false;
                    emit("update:is-add-new-payment-sidebar-active", false);
                    emit("refetch-data");
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Success",
                            text: response.data.message,
                            icon: "CheckIcon",
                            variant: "success",
                        },
                    });
                })
                .catch((error) => {
                    loading.value = false;
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Fail",
                            text: error.response.data.message,
                            icon: "HeartIcon",
                            variant: "danger",
                        },
                    });
                });
        };



        const { refFormObserver, getValidationState, resetForm } =
            formValidation(resetpaymentData);

        return {
            paymentData,
            onSubmit,
            refFormObserver,
            getValidationState,
            resetForm,
            button_text,
            loading,
            isModalActive
        };
    },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-payment-sidebar {
    .vs__dropdown-menu {
        max-height: 200px !important;
    }
}
</style>
